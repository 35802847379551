<template>
  <div class="toolbar">
    <div v-if="!hideBackgroundColorControls" class="background-color-section">
      <button
        v-for="color in backgroundColors"
        :key="color"
        class="background-color-button"
        :class="{ selected: backgroundColor === color }"
        :style="{ backgroundColor: color }"
        @click="selectBackgroundColor(color)"
      ></button>
    </div>

    <div class="d-block">
      <button @click="handleDrawClick" type="button" class="draw-button">
        <img
          class="draw-icon"
          :src="!draw ? drawIcon : drawIconHide"
          alt="draw-icon"
          :title="draw ? 'Hide drawing' : 'Draw'"
        />
      </button>
    </div>

    <div v-if="draw" class="colors-section">
      <div class="d-inline-block">
        <label @click.prevent="undoDrawStroke()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="27"
            fill="white"
            class="bi bi-arrow-counterclockwise"
            viewBox="2 -3 16 25"
          >
            <path
              fill-rule="evenodd"
              d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
            />
            <path
              d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
            />
          </svg>
        </label>
      </div>
      <div
        v-for="colorItem in colorOptions"
        :key="colorItem"
        class="d-inline-block"
      >
        <input type="radio" name="color" id="color-item" :value="colorItem" />
        <label for="color-item">
          <span
            :class="{ selectedStyle: colorItem === color }"
            @click="selectColor(colorItem)"
            :style="{ backgroundColor: colorItem }"
            class="color-item span-border"
          >
          </span>
        </label>
      </div>
    </div>

    <div class="device-button leave-block">
      <button
        class="leave"
        v-if="!inVideoCall && showVideoChat"
        @click="$store.commit('setShowDeviceModal', true)"
      >
        <div class="button-div">Start Video Call</div>
      </button>
    </div>

    <button
      v-if="!textChatHiddenByAdmin"
      class="chat-circle-button"
      @click="toggleChat"
    >
      <span v-if="unreadTextMessage" class="new-message">.</span>
      <img
        class="chat-circle-button-img"
        :src="
          showTextChat
            ? require('../assets/hide-chat.png')
            : require('../assets/show-chat.png')
        "
        :alt="showTextChat ? 'hide-chat' : 'open-chat'"
      />
    </button>

    <device-selection-modal
      :show="showDeviceModal"
      @close="$store.commit('setShowDeviceModal', false)"
      @devices-selected="handleDevicesSelected"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import drawIcon from "../assets/draw-icon.png";
import drawIconHide from "../assets/draw-icon-hide.png";
import leave from "../assets/leave_call.svg";
import DeviceSelectionModal from "./videochat/DeviceSelectionModal.vue";

export default {
  name: "Toolbar",
  components: {
    DeviceSelectionModal,
  },
  data() {
    return {
      drawIcon,
      drawIconHide,
      leave,
      backgroundColors: ["#000000", "#7C7C7C", "#FFFFFF"],
    };
  },
  computed: {
    defaultStrokes() {
      // Add some default strokes otherwise it crashes
      return Array.from({ length: this.colorOptions.length }, (_, i) => ({
        type: "dash",
        from: {
          x: 0,
          y: 0,
        },
        coordinates: [],
        color: this.colorOptions[i],
        width: 5,
        fill: false,
      }));
    },
    draw: {
      get() {
        return this.$store.state.draw;
      },
      set(value) {
        this.$store.commit("setDraw", value);
      },
    },
    colorOptions: {
      get() {
        return this.$store.state.colorOptions;
      },
      set(value) {
        this.$store.commit("setColorOptions", value);
      },
    },
    strokes: {
      get() {
        return this.$store.state.strokes;
      },
      set(value) {
        this.$store.commit("setStrokes", value);
      },
    },
    myStrokes: {
      get() {
        return this.$store.state.myStrokes;
      },
      set(value) {
        this.$store.commit("setMyStrokes", value);
      },
    },
    playerWidth: {
      get() {
        return this.$store.state.playerWidth;
      },
      set(value) {
        this.$store.commit("setPlayerWidth", value);
      },
    },
    playerHeight: {
      get() {
        return this.$store.state.playerHeight;
      },
      set(value) {
        this.$store.commit("setPlayerHeight", value);
      },
    },
    color: {
      get() {
        return this.$store.state.color;
      },
      set(value) {
        this.$store.commit("setColor", value);
      },
    },
    userEnabledDraw: {
      get() {
        return this.$store.state.userEnabledDraw;
      },
      set(value) {
        this.$store.commit("setUserEnabledDraw", value);
      },
    },
    backgroundColor: {
      get() {
        return this.$store.state.backgroundColor;
      },
      set(value) {
        this.$store.commit("setBackgroundColor", value);
      },
    },
    ...mapGetters([
      "showTextChat",
      "inVideoCall",
      "unreadTextMessage",
      "showVideoChat",
      "clientToken",
      "textChatHiddenByAdmin",
      "hideBackgroundColorControls",
      "showDeviceModal",
    ]),
  },
  methods: {
    handleDrawClick() {
      this.$parent.handleResize();
      this.draw = !this.draw;
      this.userEnabledDraw = this.draw;

      if (this.draw) {
        this.$socket.sendObj({
          message: "REQUEST_BRUSH_STROKES",
          data: {},
        });
      }
      // Erase all strokes from remote players
      if (!this.draw) {
        this.myStrokes = this.defaultStrokes;
        this.strokes = this.defaultStrokes;

        this.$socket.sendObj({
          message: "NEW_BRUSH_STROKES",
          data: {
            strokes: {
              player_width: this.playerWidth,
              player_height: this.playerHeight,
              strokes: this.myStrokes, // Sending the default ones so we can know that which user stopped drawing. Filter out that color.
            },
          },
        });
      }
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 700);
    },
    undoDrawStroke() {
      this.$emit("clicked-undo-draw-stroke", true);
    },
    toggleChat() {
      this.$store.commit("setShowTextChat", !this.showTextChat);
      this.$store.commit("setTextChatHiddenByUser", !this.showTextChat);
      this.$store.commit("setUnreadTextMessage", false);
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 300);
    },
    joinCall() {
      this.$store.commit("setInVideoCall", true);
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 700);
    },
    leaveCall() {
      this.$store.commit("setInVideoCall", false);
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 700);
    },
    selectColor(color) {
      this.color = color;
    },
    selectBackgroundColor(color) {
      this.backgroundColor = color;
      this.$store.commit("setBackgroundColor", color);
    },
    handleDevicesSelected() {
      // Join the call
      this.$store.commit("setInVideoCall", true);
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 700);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  z-index: 999999;
  width: 100%;
  padding: 0 8px;
  pointer-events: none;
  background-color: #040e17; /* Darker shade of blue */
  height: 60px; /* Reduced height */
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.05); /* Add very subtle border */
}

/* Make the actual toolbar elements interactive */
.toolbar > * {
  pointer-events: auto;
  z-index: 999999;
}

.background-color-section {
  position: absolute;
  left: 10px;
  top: 15px;
  z-index: 2;
  display: flex;
  align-items: center;
}

.background-color-button {
  width: 20px !important;
  height: 20px !important;
  margin: 2px !important;
  border: 1px solid #ccc !important;
  cursor: pointer;
  padding: 0 !important;
}

.background-color-button.selected {
  border-color: #2e58c7 !important;
  border-width: 2px !important;
}

.draw-icon {
  width: 24px;
  height: 24px;
}

.draw-button {
  position: absolute;
  top: 15px;
  left: 90px;
  z-index: 2;
}

.colors-section {
  position: absolute;
  top: 15px;
  left: 130px;
  z-index: 2;
  display: flex;
  align-items: center;
}

.colors-section label {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 2px;
  cursor: pointer;
  margin-top: 4px;
}

.colors-section .d-inline-block {
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
}

.colors-section .d-inline-block:first-child {
  margin-right: 10px;
}

.colors-section label:hover span {
  transform: scale(1.25);
}
.colors-section label span {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease-in-out;
  border-radius: 50%;
}

.colors-section input[type="radio"] {
  display: none;
}
.selectedStyle {
  transform: scale(1.25);
  border: 2px solid white;
}

.colors-section label span.red {
  background: #db2828;
}

.colors-section label span.green {
  background: #21ba45;
}

.colors-section label span.yellow {
  background: #fbbd08;
}

@media (max-width: 570px) {
  .colors-section {
    left: 9px !important;
  }

  .action-btn-first {
    left: 18px !important;
  }

  .colors-section label {
    margin-right: 6px !important;
  }
}

.device-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
}

.device-button button {
  background-color: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #4caf50;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.device-button button:hover {
  background-color: rgba(76, 175, 80, 0.1);
  border-color: rgba(0, 255, 0, 0.5);
}

.device-button button:focus {
  outline: none;
}

.device-button .button-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.device-button .icon {
  display: none;
}

.chat-circle-button {
  position: absolute;
  right: 5px;
  top: 15px;
  z-index: 12;
  width: 35px !important;
  height: 28px !important;
  border-radius: 4px;
  background: transparent;
  border: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.chat-circle-button:hover {
  opacity: 0.8;
}

.chat-circle-button-img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.new-message {
  color: red;
  font-size: 70px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -65px;
  right: -5px;
}

/* iOS specific styles */
@supports (-webkit-touch-callout: none) {
  .chat-circle-button {
    width: 35px !important;
    height: 28px !important;
    padding: 4px;
  }

  .chat-circle-button-img {
    width: 24px;
    height: 24px;
  }
}
</style>
