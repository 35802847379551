<template>
  <div class="conversation">
    <div class="conversation__chat-wrapper">
      <div class="conversation__content">
        <div ref="chatRef" class="conversation__chat">
          <DynamicScroller
            ref="scroller"
            class="scroller"
            :items="messages"
            :min-item-size="54"
            :emit-update="true"
            :emit-resize="true"
            :buffer="3500"
          >
            <template #default="{ item, index, active }">
              <DynamicScrollerItem
                v-if="item.id"
                :item="item"
                :active="active"
                :data-index="index"
                :data-active="active"
                class="conversation__chat-message chat-message"
                :class="
                  item?.state?.author === userID
                    ? 'chat-message-mine'
                    : 'chat-message-default'
                "
              >
                <div :key="index" class="chat-message__wrapper">
                  <div v-if="0" class="chat-message__author">
                    <img src="../../assets/play.png" alt="test" />
                  </div>
                  <div class="chat-message__content">
                    <div class="chat-message__name">
                      {{ item?.state?.name }}:
                    </div>
                    <ChatMessageFile
                      v-if="item?.state?.medias?.length"
                      :message-data="item"
                    />
                    <div v-else class="chat-message__message">
                      {{ item?.state?.body }}
                    </div>
                    <div class="chat-message__timestamp">
                      {{ formatDate(item?.state?.timestamp) }}
                    </div>
                  </div>
                </div>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </div>
        <transition>
          <button
            v-if="!isOnBottomScroll"
            class="conversation__scroll-down"
            :class="{ 'conversation__scroll-down--docked': docked }"
            @click="scrollToBottom"
          >
            <img src="@/assets/arrow-down.png" alt="scroll-down" />
          </button>
        </transition>
      </div>
    </div>
    <div class="conversation__input send-message">
      <div class="send-message__input-wrapper">
        <textarea
          @keyup.enter="sendMessage"
          v-model="messageText"
          placeholder="Type a message..."
          autocomplete="nope"
          ref="inputSend"
          name="message"
          rows="1"
          @input="autoGrow"
        ></textarea>
      </div>
      <div class="send-message__actions">
        <ChatFooterFile
          :show-scroll-down="!isOnBottomScroll"
          :docked="docked"
          @file-selected="selectedFiles = $event"
        />
        <ChatFooterEmojiPicker @select-emoji="onSelectEmoji" />
        <button class="send-message__action" @click="sendMessage">
          <img
            v-if="!isBusySend"
            class="send-message__send"
            src="@/assets/send-message.png"
            alt="send"
          />
          <img
            v-else
            class="send-message__loading"
            src="@/assets/loading.png"
            alt="loading"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import ChatFooterFile from "@/components/textchat/ChatFooterFile.vue";
import ChatMessageFile from "@/components/textchat/ChatMessageFile.vue";
import { mapGetters } from "vuex";
import ChatSkeleton from "@/components/ChatSkeleton.vue";
import ChatFooterEmojiPicker from "./ChatFooterEmojiPicker.vue";
import {
  DynamicScroller,
  DynamicScrollerItem,
  RecycleScroller,
} from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { nextTick } from "vue";

export default {
  components: {
    ChatFooterEmojiPicker,
    ChatSkeleton,
    ChatMessageFile,
    ChatFooterFile,
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: ["conversation", "userID", "docked"],
  data() {
    return {
      messages: [],
      messageText: "",
      isSignedInUser: false,
      participantsMap: {},
      isBusySend: false,
      chatScrollHeight: 0,
      isOnBottomScroll: true,
      scrollTimeout: null,
      isResizing: false,
      startX: 0,
      startWidth: 0,
    };
  },
  async mounted() {
    // Each message has an author property that is the userID of the user that sent it
    // However each participant has a name that so we map that name to a new name property
    // on the message to display it in a user friendly way

    // Get participants to save the author = name mapping
    await this.getParticipants();

    // Parse messages and add the name property
    this.conversation.getMessages().then(async (newMessages) => {
      newMessages.items = newMessages.items.map((message) => {
        return {
          ...message,
          state: {
            ...message.state,
            name: this.participantsMap[message.state.author],
          },
        };
      });
      this.messages = [...this.messages, ...newMessages.items].map((el) => ({
        ...el,
        id: el.state.sid,
      }));
      this.debouncedScrollToBottom();
    });

    // Listen for scroll-to-bottom event
    this.$el.addEventListener("scroll-to-bottom", this.debouncedScrollToBottom);

    // Parse new messages and add the name property
    this.conversation.on("messageAdded", (message) => {
      const isMineMessage = message.state.author === this.userID;
      if (!isMineMessage && this.showTextChat) {
        this.isOnBottomScroll = this.checkIsUserOnBottomScroll();
      }
      message = {
        ...message,
        state: {
          ...message.state,
          name: this.participantsMap[message.state.author],
        },
      };
      this.messages = [...this.messages, message].map((el) => ({
        ...el,
        id: el.state.sid,
      }));
      if (!this.$store.getters["showTextChat"]) {
        this.unreadTextMessage = true;
      }
      if (this.isOnBottomScroll) {
        this.debouncedScrollToBottom();
      }
    });

    // Add new participants to the map so we can get their name when they send a message
    this.conversation.on("participantJoined", async (participant) => {
      this.participantsMap[participant.identity] = participant.attributes.name;
    });
    document
      .querySelector(".vue-recycle-scroller")
      ?.addEventListener("scroll", this.onScrollChat);
  },
  beforeDestroy() {
    // Remove scroll-to-bottom event listener
    this.$el.removeEventListener(
      "scroll-to-bottom",
      this.debouncedScrollToBottom
    );
    clearTimeout(this.scrollTimeout);
    document
      .querySelector(".vue-recycle-scroller")
      ?.removeEventListener("scroll", this.onScrollChat);
    document.removeEventListener("mousemove", this.handleConversationResize);
    document.removeEventListener("mouseup", this.stopConversationResize);
  },
  computed: {
    ...mapGetters({
      selectedFiles: "chat/getSelectedFiles",
    }),
    showTextChat: {
      get() {
        return this.$store.state.showTextChat;
      },
      set(value) {
        this.$store.commit("setShowTextChat", value);
      },
    },
    unreadTextMessage: {
      get() {
        return this.$store.state.unreadTextMessage;
      },
      set(value) {
        this.$store.commit("setUnreadTextMessage", value);
      },
    },
    textChatHiddenByUser: {
      get() {
        return this.$store.state.textChatHiddenByUser;
      },
      set(value) {
        this.$store.commit("setTextChatHiddenByUser", value);
      },
    },
  },
  methods: {
    async getParticipants() {
      let participants = await this.conversation.getParticipants();
      participants.forEach((participant) => {
        this.participantsMap[participant.identity] =
          participant.attributes.name;
      });
    },
    async sendMessage() {
      if (this.isBusySend) return;
      if (Object.keys(this.selectedFiles)?.length) {
        await this.sendMediaMessage();
      } else {
        this.messageText && (await this.sendMessageText());
      }
      this.messageText = "";
      // Reset textarea height to standard height
      if (this.$refs.inputSend) {
        this.$refs.inputSend.style.height = "40px";
      }
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    },
    async sendMediaMessage() {
      this.isBusySend = true;
      let mediaOptions = [];
      for (let file of this.selectedFiles) {
        const blob = new Blob([file], { type: "text/plain" });
        mediaOptions.push({
          contentType: file.type,
          filename: file.name,
          media: blob,
        });
      }
      const prepearedMessage = await this.conversation.prepareMessage();
      mediaOptions.forEach((media) => {
        prepearedMessage.addMedia(media);
      });
      prepearedMessage.setBody(this.messageText);
      const builded = await prepearedMessage.build();
      await builded.send();
      this.$store.dispatch("chat/setSelectedFiles", []);
      this.isBusySend = false;
    },
    async sendMessageText() {
      this.conversation.sendMessage(this.messageText).then(() => {
        this.messageText = "";
      });
    },
    formatDate: function (date) {
      const d = dayjs(date);
      return d.format("HH:mm");
    },
    onSelectEmoji(event) {
      this.messageText += event.emoji;
      this.$refs.inputSend.focus();
    },
    closeCometChat() {
      this.$store.commit("setShowTextChat", false);
      this.textChatHiddenByUser = false;
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 700);
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 3000);
    },
    scrollToBottom() {
      this.$refs.scroller?.scrollToBottom();
      this.isOnBottomScroll = true;
    },
    checkIsUserOnBottomScroll() {
      const scrollTop =
        Math.round(this.$refs?.scroller?.$el?.scrollTop) +
        Math.round(this.$refs?.scroller?.$el?.clientHeight);
      const totalScrollArea = this.$refs?.scroller?.$el?.scrollHeight;
      const totalMessages = document.querySelectorAll(
        ".vue-recycle-scroller__item-view"
      );
      if (!totalScrollArea || !totalMessages) return;
      // offset height
      const lastMessageHeight =
        totalMessages[totalMessages.length - 1]?.clientHeight;
      // is user on bottom
      return scrollTop >= totalScrollArea - lastMessageHeight;
    },
    onScrollChat() {
      !this.isOnBottomScroll &&
        this.showTextChat &&
        (this.isOnBottomScroll = this.checkIsUserOnBottomScroll());
    },
    autoGrow(event) {
      const textarea = event.target;
      const currentHeight = textarea.style.height;
      textarea.style.height = "40px"; // Reset to initial height
      const newHeight = Math.max(40, Math.min(textarea.scrollHeight, 120)); // Ensure minimum height of 40px
      textarea.style.height = newHeight + "px";

      // Only adjust bottom if we're expanding beyond the first line
      if (currentHeight !== textarea.style.height && newHeight > 40) {
        const heightDiff = parseInt(newHeight) - parseInt(currentHeight);
        const currentBottom = parseFloat(textarea.style.bottom);
        textarea.style.bottom = `${currentBottom - heightDiff}px`;
      }

      // Ensure text stays within bounds by adjusting padding
      if (newHeight >= 120) {
        textarea.style.paddingBottom = "12px";
      } else {
        textarea.style.paddingBottom = "8px";
      }
    },
    debouncedScrollToBottom() {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    },
    startConversationResize(e) {
      this.isResizing = true;
      const clientX = e.type.includes("touch")
        ? e.touches[0].clientX
        : e.clientX;
      this.startX = clientX;
      this.startWidth = this.$el.offsetWidth;
      document.body.classList.add("resizing");

      document.addEventListener("mousemove", this.handleConversationResize);
      document.addEventListener("touchmove", this.handleConversationResize);
      document.addEventListener("mouseup", this.stopConversationResize);
      document.addEventListener("touchend", this.stopConversationResize);
    },

    handleConversationResize(e) {
      if (!this.isResizing) return;

      requestAnimationFrame(() => {
        const clientX = e.type.includes("touch")
          ? e.touches[0].clientX
          : e.clientX;
        const width = Math.max(300, this.startWidth + (this.startX - clientX));
        if (this.$el) {
          this.$el.style.width = `${width}px`;
        }
      });
    },

    stopConversationResize() {
      this.isResizing = false;
      document.body.classList.remove("resizing");
      document.removeEventListener("mousemove", this.handleConversationResize);
      document.removeEventListener("touchmove", this.handleConversationResize);
      document.removeEventListener("mouseup", this.stopConversationResize);
      document.removeEventListener("touchend", this.stopConversationResize);
    },
  },
  watch: {
    showTextChat(value) {
      value &&
        setTimeout(() => {
          this.scrollToBottom();
        }, 500);
    },
  },
};
</script>

<style lang="scss">
.conversation {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: #050e16;
  padding: 1.25rem 0.25rem 0 0.25rem;
  min-width: 300px;

  &__chat-wrapper {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100% - 65px);
    padding: 0 0.75rem;
    padding-bottom: 0.5rem;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__chat {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__input {
    display: flex;
    padding: 0.75rem;
    column-gap: 0.5rem;
    height: 85px;
    flex-shrink: 0;
    background: #040e17;
    margin-bottom: 0;
    position: relative;
    transition: height 0.2s ease;
    align-items: flex-end;
    padding-bottom: 0.75rem;
  }

  &__scroll-down {
    position: absolute;
    bottom: 1rem;
    right: 50%;
    transform: translateX(50%);
    background: #374459;
    border-radius: 50%;
    padding: 0;
    width: 2rem;
    height: 2rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 1rem;
      height: 1rem;
    }

    &--docked {
      bottom: 5rem;
      width: 3rem;
      height: 3rem;

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.send-message {
  &__input-wrapper {
    flex: 1;
    position: relative;
    min-width: 0;
    height: 40px;
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    height: 40px;
    flex-shrink: 0;
    margin-bottom: 0.75rem;
  }

  textarea {
    width: 100%;
    min-height: 40px;
    max-height: 120px;
    padding: 8px 12px;
    padding-right: 20px;
    border: none;
    border-radius: 4px;
    background: #374459;
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    resize: none;
    overflow-y: auto;
    transition: all 0.2s ease;
    box-sizing: border-box;
    height: 40px;
    transform-origin: bottom;

    &:focus {
      outline: none;
      background: #374459;
    }

    &::placeholder {
      color: #888;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #374459;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #51555b;
      border-radius: 3px;
    }
  }

  &__send {
    width: 1.25rem;
    height: 1.25rem;
  }

  &__loading {
    width: 1.5rem;
    height: 1.5rem;
    animation: spin 1s linear infinite;
  }

  &__action {
    padding: 0;
  }
}

.chat-message {
  display: flex;
  padding: 0.5rem 0;

  &__wrapper {
    max-width: 50%;
    color: #fff;
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__content {
    margin-right: 8px;
    word-break: break-word;
  }

  &__author {
    img {
      width: 24px;
      height: 24px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }

  &__message {
    text-align: left;
    color: #fff;
    border-radius: 1rem;
    padding: 0.5rem;
    background-color: #374459;
    font-size: 14px; // Match input font size
    line-height: 1.5; // Match input line height
  }
  &__name {
    color: #fff;
    padding-right: 8px;
    font-size: 12px; // Slightly smaller than message text
    display: inline-block;
    white-space: nowrap;
  }

  &__timestamp {
    font-size: 11px;
    margin-top: 0.25rem;
  }

  &-mine {
    justify-content: flex-start;
    .chat-message__wrapper {
      .chat-message__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &-default {
    justify-content: flex-end;

    .chat-message__wrapper {
      flex-direction: row-reverse;

      .chat-message__content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #51555b;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: #184059;
  border-radius: 12px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: #1a1a1a;
  border-bottom: 1px solid #333;
  position: relative;
  z-index: 1;

  &__close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.conversation__input {
  textarea {
    width: 93%;
    min-height: 24px;
    max-height: 120px; // 5 lines max
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background: #37445962;
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    resize: none;
    overflow-y: auto;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    transform-origin: bottom;
    transition: height 0.2s ease;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #888;
    }
  }
}
/* Mobile styles */
@media screen and (max-width: 768px) {
  .conversation__input {
    padding-bottom: 1rem;
  }

  .send-message__actions {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }

  .conversation__chat-wrapper {
    padding-bottom: 1rem;
  }

  .conversation__chat {
    padding-bottom: 1rem;
  }
}

/* Add iPad-specific styles */
@supports (-webkit-touch-callout: none) {
  .conversation {
    padding-bottom: 1.5rem;
  }
}

/* iPhone specific styles */
@media only screen and (max-width: 500px) {
  @supports (-webkit-touch-callout: none) {
    .conversation {
      padding-bottom: 4rem;
    }
  }
}
</style>
