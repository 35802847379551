<template>
  <div class="device-selection-modal" v-show="show">
    <div class="modal-content">
      <h2 class="modal-title">Setup Your Call</h2>
      <p class="modal-subtitle">Select your input devices before joining.</p>

      <div class="camera-preview">
        <video ref="videoPreview" autoplay playsinline muted></video>
        <div v-if="backgroundBlurEnabled" class="blur-preview-text">
          Background blur will be applied in the call
        </div>
      </div>

      <div class="device-section">
        <label class="device-label">
          <span class="device-icon">📷</span>
          Camera
        </label>
        <select
          v-model="selectedVideoDevice"
          class="device-select"
          @change="updateVideoPreview"
        >
          <option :value="'none'" selected>No Camera</option>
          <option
            v-for="device in videoDevices"
            :key="device.deviceId"
            :value="device.deviceId"
          >
            {{ device.label || "Unnamed Camera" }}
          </option>
        </select>
      </div>

      <div class="device-section">
        <label class="device-label">
          <span class="device-icon">🎤</span>
          Microphone
        </label>
        <select v-model="selectedAudioDevice" class="device-select">
          <option value="none">No Microphone</option>
          <option
            v-for="device in audioDevices"
            :key="device.deviceId"
            :value="device.deviceId"
          >
            {{ device.label || "Unnamed Microphone" }}
          </option>
        </select>
      </div>

      <div class="device-section">
        <label class="device-label">
          <span class="device-icon">🔈</span>
          Speaker
        </label>
        <select v-model="selectedAudioOutputDevice" class="device-select">
          <option value="none">Default Speaker</option>
          <option
            v-for="device in audioOutputDevices"
            :key="device.deviceId"
            :value="device.deviceId"
          >
            {{ device.label || "Unnamed Speaker" }}
          </option>
        </select>
      </div>

      <div class="device-section" v-if="isVideoSupported">
        <label class="device-label">
          <span class="device-icon">🎨</span>
          Background Blur
        </label>
        <div class="toggle-container">
          <label class="toggle">
            <input type="checkbox" v-model="backgroundBlurEnabled" />
            <span class="toggle-slider"></span>
          </label>
          <span class="toggle-label">{{
            backgroundBlurEnabled ? "Enabled" : "Disabled"
          }}</span>
        </div>
      </div>

      <div class="device-section" v-if="isNoiseCancellationSupported">
        <label class="device-label">
          <span class="device-icon">🎵</span>
          Noise Cancellation
        </label>
        <div class="toggle-container">
          <label class="toggle">
            <input type="checkbox" v-model="noiseCancellationEnabled" />
            <span class="toggle-slider"></span>
          </label>
          <span class="toggle-label">{{
            noiseCancellationEnabled ? "Enabled" : "Disabled"
          }}</span>
        </div>
      </div>

      <div class="modal-actions">
        <button class="cancel-button" @click="cancel">Cancel</button>
        <button
          class="join-button"
          @click="joinCall"
          :disabled="!canJoinCall"
          :class="{ disabled: !canJoinCall }"
        >
          {{ canJoinCall ? "Join Call" : "Waiting for permissions..." }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import daily from "@daily-co/daily-js";

export default {
  name: "DeviceSelectionModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      videoDevices: [],
      audioDevices: [],
      audioOutputDevices: [],
      selectedVideoDevice:
        localStorage.getItem("selectedVideoDevice") || "none",
      selectedAudioDevice:
        localStorage.getItem("selectedAudioDevice") || "none",
      selectedAudioOutputDevice:
        localStorage.getItem("selectedAudioOutputDevice") || "none",
      backgroundBlurEnabled:
        localStorage.getItem("backgroundBlurEnabled") === "true",
      noiseCancellationEnabled:
        localStorage.getItem("noiseCancellationEnabled") === null
          ? true
          : localStorage.getItem("noiseCancellationEnabled") === "true",
      hasPermissions: false,
      currentStream: null,
      browserInfo: null,
      forceEnable: false,
    };
  },
  computed: {
    isVideoSupported() {
      return this.browserInfo?.supportsVideoProcessing || false;
    },
    isNoiseCancellationSupported() {
      return this.browserInfo?.supportsAudioProcessing || false;
    },
    canJoinCall() {
      return (this.hasPermissions && this.currentStream) || this.forceEnable;
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.requestPermissions();
      } else {
        this.stopVideoPreview();
      }
    },
  },
  methods: {
    async requestPermissions() {
      try {
        // Request permissions for camera and microphone
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        // Stop the stream since we just needed it for permissions
        stream.getTracks().forEach((track) => track.stop());

        this.hasPermissions = true;
        await this.enumerateDevices();

        // Listen for device changes
        navigator.mediaDevices.addEventListener(
          "devicechange",
          this.enumerateDevices
        );

        // Start video preview if a device is selected
        if (this.selectedVideoDevice && this.selectedVideoDevice !== "none") {
          await this.updateVideoPreview();
        }
      } catch (error) {
        console.error("Error requesting permissions:", error);
        // Handle permission denied case
        if (error.name === "NotAllowedError") {
          alert(
            "Please allow camera and microphone access to select your devices."
          );
          this.$emit("close");
        }
      }
    },
    async updateVideoPreview() {
      // Stop any existing stream
      this.stopVideoPreview();

      if (!this.selectedVideoDevice || this.selectedVideoDevice === "none") {
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: { exact: this.selectedVideoDevice },
          },
        });

        this.currentStream = stream;
        const videoElement = this.$refs.videoPreview;
        if (videoElement) {
          videoElement.srcObject = stream;
        }
      } catch (error) {
        console.error("Error updating video preview:", error);
      }
    },
    stopVideoPreview() {
      if (this.currentStream) {
        this.currentStream.getTracks().forEach((track) => track.stop());
        this.currentStream = null;
      }
    },
    async enumerateDevices() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();

        this.videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        this.audioDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );
        this.audioOutputDevices = devices.filter(
          (device) => device.kind === "audiooutput"
        );

        // Set default selections if not already set
        if (!this.selectedVideoDevice || this.selectedVideoDevice === "none") {
          this.selectedVideoDevice =
            this.videoDevices.length > 0
              ? this.videoDevices[0].deviceId
              : "none";
        } else if (
          this.videoDevices.length &&
          !this.videoDevices.find(
            (d) => d.deviceId === this.selectedVideoDevice
          )
        ) {
          this.selectedVideoDevice = this.videoDevices[0].deviceId;
        }
        if (!this.selectedAudioDevice || this.selectedAudioDevice === "none") {
          this.selectedAudioDevice =
            this.audioDevices.length > 0
              ? this.audioDevices[0].deviceId
              : "none";
        }
        if (
          !this.selectedAudioOutputDevice ||
          this.selectedAudioOutputDevice === "none"
        ) {
          this.selectedAudioOutputDevice =
            this.audioOutputDevices.length > 0
              ? this.audioOutputDevices[0].deviceId
              : "none";
        }
      } catch (error) {
        console.error("Error enumerating devices:", error);
      }
    },
    joinCall() {
      // Save selected devices to localStorage
      localStorage.setItem("selectedVideoDevice", this.selectedVideoDevice);
      localStorage.setItem("selectedAudioDevice", this.selectedAudioDevice);
      localStorage.setItem(
        "selectedAudioOutputDevice",
        this.selectedAudioOutputDevice
      );
      localStorage.setItem("backgroundBlurEnabled", this.backgroundBlurEnabled);
      localStorage.setItem(
        "noiseCancellationEnabled",
        this.noiseCancellationEnabled
      );

      // Emit devices-selected event to trigger join
      this.$emit("devices-selected");

      // Emit close event to close the modal
      this.$emit("close");
    },
    cancel() {
      this.$emit("close");
    },
  },
  beforeUnmount() {
    // Clean up device change listener
    navigator.mediaDevices.removeEventListener(
      "devicechange",
      this.enumerateDevices
    );
  },
  mounted() {
    // Print browser compatibility info
    this.browserInfo = daily.supportedBrowser();
    console.log("Browser compatibility info:", {
      supported: this.browserInfo.supported,
      mobile: this.browserInfo.mobile,
      name: this.browserInfo.name,
      version: this.browserInfo.version,
      supportsFullscreen: this.browserInfo.supportsFullscreen,
      supportsScreenShare: this.browserInfo.supportsScreenShare,
      supportsSfu: this.browserInfo.supportsSfu,
      supportsVideoProcessing: this.browserInfo.supportsVideoProcessing,
      supportsAudioProcessing: this.browserInfo.supportsAudioProcessing,
    });

    // Enable join button after 7 seconds regardless of permissions
    setTimeout(() => {
      this.forceEnable = true;
    }, 7000);
  },
};
</script>
<style scoped>
.device-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  backdrop-filter: blur(5px);
}

.modal-content {
  background-color: #0f172a;
  padding: 1.5rem;
  border-radius: 16px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.modal-title {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.5rem;
}

.modal-subtitle {
  color: #94a3b8;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 1rem;
}

.device-section {
  margin-bottom: 0.75rem;
}

.device-label {
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
}

.device-icon {
  margin-right: 0.5rem;
  font-size: 1.1rem;
}

.device-select {
  width: 100%;
  padding: 0.4rem 2rem 0.4rem 0.75rem;
  border-radius: 8px;
  background-color: #1e293b;
  border: 1px solid #334155;
  color: #ffffff;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}

.device-select::-ms-expand {
  display: none;
}

.device-select:hover {
  border-color: #60a5fa;
}

.device-select:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

.device-select option {
  background-color: #0f172a;
  color: #ffffff;
  padding: 0.5rem;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.join-button,
.cancel-button {
  padding: 0.5rem 1.25rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.join-button {
  background-color: #3b82f6;
  color: white;
}

.cancel-button {
  background-color: #334155;
  color: white;
}

.join-button:hover {
  background-color: #2563eb;
}

.cancel-button:hover {
  background-color: #475569;
}

.device-select:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: #0f172a;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #334155;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: #475569;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #334155;
  transition: 0.2s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.2s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #2e58c7;
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

.toggle-label {
  font-size: 0.95rem;
  color: #ffffff;
}

.camera-preview {
  width: 100%;
  height: 0;
  padding-bottom: 45%; /* Slightly smaller 16:9 ratio */
  background-color: #1e293b;
  border-radius: 8px;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  border: 1px solid #334155;
}

.camera-preview video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blur-preview-text {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  z-index: 1;
  pointer-events: none;
}

.join-button.disabled {
  background-color: #64748b;
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
