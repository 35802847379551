<template>
  <div class="controls-container">
    <div class="controls">
      <button @click="togglePlay" class="control-button play-pause">
        <svg v-if="isPlaying" class="icon" viewBox="0 0 24 24">
          <path fill="currentColor" d="M14,19H18V5H14M6,19H10V5H6V19Z" />
        </svg>
        <svg v-else class="icon" viewBox="0 0 24 24">
          <path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
        </svg>
      </button>
      <div class="volume-control">
        <button @click="toggleMute" class="control-button">
          <svg v-if="isMuted" class="icon" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12,4L9.91,6.09L12,8.18M4.27,3L3,4.27L7.73,9H3V15H7L12,20V13.27L16.25,17.53C15.58,18.04 14.83,18.46 14,18.7V20.77C15.38,20.45 16.63,19.82 17.68,18.96L19.73,21L21,19.73L12,10.73M19,12C19,12.94 18.8,13.82 18.46,14.64L19.97,16.15C20.62,14.91 21,13.5 21,12C21,7.72 18,4.14 14,3.23V5.29C16.89,6.15 19,8.83 19,12M16.5,12C16.5,10.23 15.5,8.71 14,7.97V10.18L16.45,12.63C16.5,12.43 16.5,12.21 16.5,12Z"
            />
          </svg>
          <svg v-else class="icon" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z"
            />
          </svg>
        </button>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          v-model="volume"
          @input="setVolume"
          class="volume-slider"
        />
      </div>
      <button @click="toggleFullscreen" class="control-button fullscreen">
        <svg v-if="isFullscreen" class="icon" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"
          />
        </svg>
        <svg v-else class="icon" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebRtcControls",
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPlaying: true,
      isMuted: true,
      isFullscreen: false,
      volume: 0,
    };
  },
  methods: {
    togglePlay() {
      if (!this.player?.videoElement) return;
      if (this.isPlaying) {
        this.player.videoElement.pause();
      } else {
        this.player.videoElement.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    toggleMute() {
      if (!this.player) return;
      if (this.isMuted) {
        this.player.unmute();
        this.volume = 0.3; // Set a comfortable initial volume
        this.player.videoElement.volume = 0.3;
      } else {
        this.player.mute();
        this.volume = 0;
        this.player.videoElement.volume = 0;
      }
      this.isMuted = !this.isMuted;
    },
    setVolume(event) {
      if (!this.player?.videoElement) return;
      const newVolume = parseFloat(event.target.value);
      this.player.videoElement.volume = newVolume;
      this.volume = newVolume;

      if (newVolume > 0 && this.isMuted) {
        this.player.unmute();
        this.isMuted = false;
      } else if (newVolume === 0) {
        this.player.mute();
        this.isMuted = true;
      }
    },
    toggleFullscreen() {
      if (!this.player?.videoElement) return;

      // Handle iOS Safari specifically
      if (this.player.videoElement.webkitEnterFullscreen) {
        if (!document.webkitFullscreenElement) {
          this.player.videoElement.webkitEnterFullscreen();
        } else {
          document.webkitExitFullscreen();
        }
      } else {
        // Standard fullscreen API
        if (!document.fullscreenElement) {
          this.player.videoElement.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
      this.isFullscreen = !this.isFullscreen;
    },
    setupEventListeners() {
      if (!this.player?.videoElement) return;

      // Listen for fullscreen changes - handle both standard and webkit
      document.addEventListener("fullscreenchange", () => {
        this.isFullscreen = !!document.fullscreenElement;
      });
      document.addEventListener("webkitfullscreenchange", () => {
        this.isFullscreen = !!document.webkitFullscreenElement;
      });

      // Listen for video play/pause events
      this.player.videoElement.addEventListener("play", () => {
        this.isPlaying = true;
      });
      this.player.videoElement.addEventListener("pause", () => {
        this.isPlaying = false;
      });

      // Listen for volume and mute changes
      this.player.videoElement.addEventListener("volumechange", () => {
        this.handleVolumeChange();
      });

      // iOS specific events
      this.player.videoElement.addEventListener("webkitbeginfullscreen", () => {
        this.isFullscreen = true;
        // Ensure volume state is correct when entering fullscreen
        if (!this.isMuted) {
          this.player.unmute();
          this.player.videoElement.volume = this.volume;
        }
      });

      this.player.videoElement.addEventListener("webkitendfullscreen", () => {
        this.isFullscreen = false;
        // Restore volume state when exiting fullscreen
        this.handleVolumeChange();
      });
    },
    handleVolumeChange() {
      if (!this.player?.videoElement) return;

      if (this.player.videoElement.muted) {
        this.volume = 0;
      } else {
        this.volume = this.player.videoElement.volume;
      }
      this.isMuted = this.player.videoElement.muted;
    },
  },
  watch: {
    player: {
      immediate: true,
      handler(newPlayer) {
        if (newPlayer?.videoElement) {
          this.setupEventListeners();
        }
      },
    },
  },
  beforeUnmount() {
    if (this.player?.videoElement) {
      this.player.videoElement.removeEventListener("play", () => {});
      this.player.videoElement.removeEventListener("pause", () => {});
      this.player.videoElement.removeEventListener("volumechange", () => {});
      this.player.videoElement.removeEventListener(
        "webkitbeginfullscreen",
        () => {}
      );
      this.player.videoElement.removeEventListener(
        "webkitendfullscreen",
        () => {}
      );
    }
    document.removeEventListener("fullscreenchange", () => {});
    document.removeEventListener("webkitfullscreenchange", () => {});
  },
};
</script>

<style scoped>
.controls-container {
  pointer-events: none;
  display: flex;
  justify-content: center;
}

.controls {
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 300px;
  pointer-events: auto;
  background: transparent;
  padding: 10px 20px;
}

.control-button {
  background: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-pause {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-pause .icon {
  width: 25px;
  height: 25px;
}

.icon {
  width: 20px;
  height: 20px;
}

.fullscreen .icon {
  width: 25px;
  height: 25px;
}

.volume-control {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0 0 150px;
}

.volume-slider {
  width: 100%;
  height: 5px;
  -webkit-appearance: none;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  outline: none;
  transition: background 0.2s ease;
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.1s ease;
}

.volume-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  transition: transform 0.1s ease;
}

.volume-slider:hover::-webkit-slider-thumb {
  transform: scale(1.1);
}

.volume-slider:hover::-moz-range-thumb {
  transform: scale(1.1);
}

/* Mobile styles */
@media (max-width: 768px) and (orientation: portrait) {
  .controls-container {
    position: fixed;
    bottom: 201px; /* Position above video-chat (120px) and toolbar (75px) */
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .controls {
    max-width: 100%;
    width: auto;
    display: flex;
    align-items: center;
    gap: 15px;
    z-index: 100;
  }

  .volume-control {
    flex: 0 0 120px;
  }

  .control-button {
    min-width: 32px;
    height: 32px;
  }
}

/* iOS landscape specific styles */
@media (max-width: 768px) and (orientation: landscape) {
  @supports (-webkit-touch-callout: none) {
    .controls-container {
      position: fixed;
      bottom: 70px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
