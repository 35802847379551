<template>
  <button v-if="!inVideoCall" @click="joinCall" style="display: none">
    Join
  </button>
  <call-tile
    v-else-if="inVideoCall"
    :leave-call="leaveCall"
    :name="userName"
    :room-url="roomUrl"
    :videoChatToken="videoChatToken"
    @update:controls="updateControls"
  />
</template>

<script>
import CallTile from "./CallTile.vue";
import { mapGetters } from "vuex";

export default {
  name: "VideoChat",
  components: {
    CallTile,
  },
  computed: {
    ...mapGetters(["sessionID", "videoChatToken", "userName", "inVideoCall"]),
    roomUrl() {
      return `https://pixelview.daily.co/${this.sessionID}`;
    },
  },
  methods: {
    joinCall() {
      this.$store.commit("setInVideoCall", true);
    },
    leaveCall() {
      this.$store.commit("setInVideoCall", false);
    },
    updateControls(controls) {
      this.$emit("update:controls", controls);
    },
  },
};
</script>
