<template>
  <div
    ref="chatWrapper"
    class="chat-wrapper position-relative"
    :class="{ 'chat-wrapper__gap': sessionDescription && !loading && !docked }"
    :style="{ width: docked ? '100%' : chatWidth + 'px' }"
  >
    <Transition>
      <div
        class="chat-component"
        :class="{ 'chat-overlay': docked }"
        v-show="showTextChat"
      >
        <div
          class="chat-resize-handle"
          @mousedown="startChatResize"
          @touchstart="startChatResize"
        ></div>
        <chat-skeleton
          class="w-100"
          v-if="loading && showTextChat && !docked"
        ></chat-skeleton>
        <Conversation
          v-if="conversation && !loading"
          :conversation="conversation"
          :userID="userID"
          :docked="docked"
          @handle-resize="$emit('handle-resize')"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import { Client as ConversationsClient } from "@twilio/conversations";
import Conversation from "./Conversation";
import ChatSkeleton from "@/components/ChatSkeleton.vue";
import { mapGetters } from "vuex";

export default {
  components: { ChatSkeleton, Conversation },
  props: {
    token: {
      type: String,
      required: true,
    },
    userID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      conversation: null,
      isConnected: false,
      resizeTimeout: null,
      loading: false,
      docked: false,
      isShowMobileChat: false,
      window: {
        width: 0,
      },
      isChatResizing: false,
      chatStartX: 0,
      chatStartWidth: 0,
      chatWidth: 350,
      resizeScrollTimeout: null,
    };
  },
  created() {
    this.initConversationsClient();
  },
  mounted() {
    let self = this;
    self.docked = window.innerWidth <= 991;
    self.$store.commit("setShowTextChat", !self.docked);
    self.handleResize();

    setTimeout(function () {
      window.addEventListener("resize", self.handleResize);
    }, 1700);
  },
  computed: {
    ...mapGetters({
      sessionDescription: "sessionDescription",
      getEmojiPickerVisible: "chat/getEmojiPickerVisible",
    }),
    showTextChat: {
      get() {
        return this.$store.state.showTextChat;
      },
      set(value) {
        this.$store.commit("setShowTextChat", value);
      },
    },
    unreadTextMessage: {
      get() {
        return this.$store.state.unreadTextMessage;
      },
      set(value) {
        this.$store.commit("setUnreadTextMessage", value);
      },
    },
  },
  methods: {
    async initConversationsClient() {
      this.loading = true;
      window.conversationsClient = ConversationsClient;
      this.conversationsClient = new ConversationsClient(this.token);

      this.conversationsClient.on("initFailed", ({ error }) => {
        console.error("Failed to initialize the Conversations client", error);
        this.loading = false;
      });
      this.conversationsClient.on("initialized", () => {
        console.log("initialized");
        //this.joinedConversation()
        this.loading = false;
      });
      this.conversationsClient.on("conversationJoined", (conversation) => {
        this.conversation = conversation;
        this.loading = false;
      });
      this.conversationsClient.on("connectionStateChanged", (state) => {
        switch (state) {
          case "connected":
            console.log("connected");
            this.isConnected = true;
            break;
          case "disconnecting":
            console.log("disconnecting");
            break;
          case "disconnected":
            console.log("disconnected");
            break;
          case "denied":
            console.log("denied");
            break;
        }
      });
    },
    handleResize() {
      clearTimeout(this.resizeTimeout);
      if (window.innerWidth !== this.window.width) {
        this.window.width = window.innerWidth;
        this.resizeTimeout = setTimeout(() => {
          if (this.window.width <= 991) {
            this.docked = true;
            this.$store.commit("setShowTextChat", false);
            setTimeout(() => {
              this.$emit("handle-resize");
            }, 700);
          }

          if (this.window.width > 991) {
            this.docked = false;
            setTimeout(() => {
              this.$emit("handle-resize");
            }, 700);
          }
        }, 800);
      }
    },
    toggleCometChat() {
      // Cancel drawing before toggling chat
      this.$store.dispatch("cancelDrawing");

      this.showTextChat = !this.showTextChat;
      this.textChatHiddenByUser = !this.showTextChat;
      if (this.showTextChat) this.unreadTextMessage = false;

      setTimeout(() => {
        this.$emit("handle-resize");
      }, 100);
    },
    startChatResize(e) {
      if (!this.$refs.chatWrapper) return;

      this.isChatResizing = true;
      const clientX = e.type.includes("touch")
        ? e.touches[0].clientX
        : e.clientX;
      this.chatStartX = clientX;
      this.chatStartWidth = this.$refs.chatWrapper.offsetWidth;
      document.body.classList.add("resizing");

      document.addEventListener("mousemove", this.handleChatResize);
      document.addEventListener("touchmove", this.handleChatResize);
      document.addEventListener("mouseup", this.stopChatResize);
      document.addEventListener("touchend", this.stopChatResize);
    },

    debouncedScrollToBottom() {
      clearTimeout(this.resizeScrollTimeout);
      this.resizeScrollTimeout = setTimeout(() => {
        this.$refs.chatWrapper
          .querySelector(".conversation")
          ?.dispatchEvent(new Event("scroll-to-bottom"));
      }, 100);
    },

    handleChatResize(e) {
      if (!this.isChatResizing || !this.$refs.chatWrapper) return;

      requestAnimationFrame(() => {
        const clientX = e.type.includes("touch")
          ? e.touches[0].clientX
          : e.clientX;
        const width = Math.max(
          200,
          this.chatStartWidth + (this.chatStartX - clientX)
        );
        this.chatWidth = width;
        this.$refs.chatWrapper.style.width = `${width}px`;

        this.debouncedScrollToBottom();
      });
    },

    stopChatResize() {
      this.isChatResizing = false;
      document.body.classList.remove("resizing");
      document.removeEventListener("mousemove", this.handleChatResize);
      document.removeEventListener("touchmove", this.handleChatResize);
      document.removeEventListener("mouseup", this.stopChatResize);
      document.removeEventListener("touchend", this.stopChatResize);

      this.debouncedScrollToBottom();
    },
  },
  watch: {
    showTextChat(value) {
      if (value && this.docked) {
        document.body.classList.add("remove-scroll");
      } else {
        document.body.classList.remove("remove-scroll");
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.handleChatResize);
    document.removeEventListener("touchmove", this.handleChatResize);
    document.removeEventListener("mouseup", this.stopChatResize);
    document.removeEventListener("touchend", this.stopChatResize);
    clearTimeout(this.resizeScrollTimeout);
  },
};
</script>

<style lang="scss">
.chat-wrapper {
  width: 100%;
  height: calc(100vh - 75px); /* Keep existing height for non-overlay mode */
  display: flex;
  flex-direction: column;
  background: #1a1a1a;
  border-left: 1px solid #333;
  transition: width 0.3s ease;
  position: relative;
  &__toggle-chat {
    position: fixed;
    bottom: 100px;
    left: 100px;
    transform: translate(-50%);
    background: #184059;
    border: none;
    border-radius: 50%;
    padding: 0.75rem;
    cursor: pointer;
    transition: all 0.4s;
    img {
      width: 200px;
    }

    &--hidden {
      bottom: 1rem;
      left: 2rem;
    }
  }
  &__unread-messages {
    &:before {
      content: "";
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      background: #ff0000;
      border-radius: 50%;
      position: absolute;
      top: -0.15rem;
      right: 0;
    }
  }
  &__hide-toggle {
    display: none !important;
  }
  a {
    color: #42b983;
  }
}

.chat-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 42px); /* Change to 100% to match wrapper */
  position: relative;
}

.chat-resize-handle {
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: rgba(46, 88, 199, 0.3);
  cursor: col-resize;
  transition: background 0.2s;
  z-index: 1000;
}

.chat-resize-handle:hover,
.chat-resize-handle:active {
  background: rgba(46, 88, 199, 0.8);
  width: 5px;
}

.chat-overlay {
  position: fixed !important;
  top: 20px; /* Add space from top */
  left: 0;
  z-index: 1000;
  height: calc(100vh - 80px); /* Adjust height to account for top space */
  bottom: 60px; /* Space for toolbar */
  overflow: hidden;
  width: 100%;
  background: #1a1a1a;
  border-top: 1px solid rgba(255, 255, 255, 0.1); /* Add subtle border */
  border-radius: 8px 8px 0 0; /* Round top corners */
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.remove-scroll {
  overflow: hidden;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
