<template>
  <div class="controls">
    <div class="devices">
      <button @click="leaveCall" class="device-button leave">
        <img
          height="20"
          class="leave-icon"
          :src="leave"
          alt=""
          title="Leave call"
        />
      </button>
      <div id="v-step-1" class="device-button">
        <img
          v-if="videoDevices.length"
          @click="openVideoList"
          class="down-arrow-right"
          :src="downArrow"
        />
        <button @click="handleVideoClickInternal" class="device-button">
          <template v-if="isVideoEnabled">
            <img
              height="20"
              class="video-icon"
              :src="videoOn"
              alt=""
              title="Turn off camera"
            />
          </template>
          <template v-else>
            <img
              height="20"
              class="video-icon"
              :src="videoOff"
              alt=""
              title="Turn on camera"
            />
          </template>
        </button>
        <div
          v-click-outside="onClickOutside"
          v-if="videoList && videoDevices.length"
          class="position-absolute video-list"
        >
          <div class="device-section">
            <div class="device-section-title">
              <img class="icon video-icon" :src="videoOn" alt="" />
              Camera
            </div>
            <div
              v-for="(device, index) in videoDevices"
              :key="device.deviceId"
              :value="device.deviceId"
              class="form-check"
            >
              <input
                class="form-check-input"
                type="radio"
                name="video"
                :id="'video' + index"
                :value="device.deviceId"
                v-model="selectedVideoDevice"
              />
              <label
                class="form-check-label"
                :for="'video' + index"
                @click="selectVideo(device.deviceId)"
              >
                {{ device.label }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="v-step-2" class="device-button">
        <img
          v-if="audioInputDevices.length || audioOutputDevices.length"
          @click="openMicrophoneList"
          class="down-arrow-right"
          :src="downArrow"
        />
        <button
          @click="handleAudioClickInternal"
          class="microphone device-button"
        >
          <template v-if="isAudioEnabled">
            <img
              height="20"
              class="microphone-icon"
              :src="micOn"
              alt=""
              title="Mute microphone"
            />
          </template>
          <template v-else>
            <img
              height="20"
              class="microphone-icon"
              :src="micOff"
              alt=""
              title="Unmute microphone"
            />
          </template>
        </button>
        <div
          v-click-outside="onClickOutside"
          v-if="microphoneList && audioInputDevices.length"
          class="position-absolute microphone-list"
        >
          <div class="device-section">
            <div class="device-section-title">
              <img class="icon microphone-icon" :src="micOn" alt="" />
              Microphone
            </div>
            <div
              v-for="(device, index) in audioInputDevices"
              :key="device.deviceId"
              :value="device.deviceId"
              class="form-check"
            >
              <input
                class="form-check-input"
                type="radio"
                name="microphone"
                :id="'microphone' + index"
                :value="device.deviceId"
                v-model="selectedAudioDevice"
              />
              <label
                class="form-check-label"
                :for="'microphone' + index"
                @click="selectMicrophone(device.deviceId)"
              >
                {{
                  device.label ||
                  `Microphone ${audioInputDevices.indexOf(device) + 1}`
                }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="v-step-0" class="device-button">
        <img
          v-if="audioOutputDevices.length"
          @click="openAudioOutputList"
          class="down-arrow-right"
          :src="downArrow"
        />
        <button @click="toggleMute" class="speaker device-button">
          <template v-if="!videoChatAudioMuted">
            <img
              class="speaker-icon"
              :src="speaker"
              alt=""
              title="Mute video chat audio"
            />
          </template>
          <template v-else>
            <img
              class="speaker-icon"
              :src="speakerOff"
              alt=""
              title="Unmute video chat audio"
            />
          </template>
        </button>
        <div
          v-click-outside="onClickOutside"
          v-if="audioOutputList && audioOutputDevices.length"
          class="position-absolute video-list"
        >
          <div class="device-section">
            <div class="device-section-title">
              <img class="icon speaker-icon" :src="speaker" alt="" />
              Speaker
            </div>
            <div
              v-for="(device, index) in audioOutputDevices"
              :key="device.deviceId"
              :value="device.deviceId"
              class="form-check"
            >
              <input
                class="form-check-input"
                type="radio"
                name="audioOutput"
                :id="'audioOutput' + index"
                :value="device.deviceId"
                v-model="selectedAudioOutputDevices"
              />
              <label
                class="form-check-label"
                :for="'audioOutput' + index"
                @click="selectAudioOutput(device.deviceId)"
              >
                {{ device.label }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="v-step-3" class="device-button">
        <button
          @click="handleScreenshareClickInternal"
          class="screenshare device-button"
        >
          <template v-if="isScreenshareEnabled">
            <img
              height="20"
              class="screenshare-icon"
              :src="screenshare"
              alt=""
              title="Stop screen sharing"
            />
          </template>
          <template v-else>
            <img
              height="20"
              class="screenshare-icon"
              :src="screenshareOff"
              alt=""
              title="Start screen sharing"
            />
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import daily from "@daily-co/daily-js";
import vClickOutside from "click-outside-vue3";

import leave from "../../assets/leave_call.svg";
import micOff from "../../assets/mic_off.svg";
import micOn from "../../assets/mic_on.svg";
import screenShareIcon from "../../assets/screenshare.png";
import screenShareCloseIcon from "../../assets/screenshare-close.png";
import videoOff from "../../assets/vid_off.svg";
import videoOn from "../../assets/vid_on.svg";
import speaker from "../../assets/speaker.svg";
import speakerOff from "../../assets/speaker_off.svg";
import downArrow from "../../assets/down-arrow.png";
import { mapGetters } from "vuex";

export default {
  name: "CallControls",
  props: [
    "participant",
    "handleVideoClick",
    "handleAudioClick",
    "handleScreenshareClick",
    "leaveCall",
    "disableScreenShare",
  ],
  data() {
    return {
      leave,
      micOn,
      micOff,
      screenShareIcon,
      screenShareCloseIcon,
      videoOn,
      speaker,
      speakerOff,
      videoOff,
      supportsScreenshare: false,
      selectedAudioDevice:
        localStorage.getItem("selectedAudioDevice") || "default",
      selectedVideoDevice:
        localStorage.getItem("selectedVideoDevice") || "default",
      selectedAudioOutputDevices:
        localStorage.getItem("selectedAudioOutputDevice") || "default",
      videoList: false,
      microphoneList: false,
      audioOutputList: false,
      videoDevices: [],
      audioInputDevices: [],
      audioOutputDevices: [],
      isVideoEnabled: true,
      isAudioEnabled: true,
      isScreenshareEnabled: false,
      steps: [
        {
          target: "#v-step-0",
          content: "Click speaker to enable audio",
        },
      ],
      downArrow,
      screenshare: screenShareIcon,
      screenshareOff: screenShareCloseIcon,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    videoChatAudioMuted: {
      get() {
        return this.$store.getters.videoChatAudioMuted;
      },
      set(value) {
        this.$store.commit("setVideoChatAudioMuted", value);
      },
    },
    ...mapGetters(["screenShare"]),
  },
  mounted() {
    this.supportsScreenshare = daily.supportedBrowser().supportsScreenShare;
    this.enumerateDevices();
    this.popupItem = this.$el;
    this.timeoutVideoCall();

    // Initialize device states from participant
    if (this.participant) {
      this.isVideoEnabled = this.participant.video;
      this.isAudioEnabled = this.participant.audio;
      this.isScreenshareEnabled = this.participant.screen;
    }
  },
  methods: {
    toggleMute() {
      this.videoChatAudioMuted = !this.videoChatAudioMuted;
    },
    timeoutVideoCall() {
      setTimeout(() => {
        this.leaveCall();
      }, 43200000);
    },
    endCall() {
      this.$store.commit("setInVideoCall", false);
      this.$store.commit("setScreenShare", null);
    },
    async handleVideoClickInternal() {
      await this.handleVideoClick();
      this.isVideoEnabled = !this.isVideoEnabled;
      setTimeout(() => {
        this.enumerateDevices();
      }, 2000);
    },
    async handleAudioClickInternal() {
      await this.handleAudioClick();
      this.isAudioEnabled = !this.isAudioEnabled;
      setTimeout(() => {
        this.enumerateDevices();
      }, 2000);
    },
    async handleScreenshareClickInternal() {
      await this.handleScreenshareClick();
      this.isScreenshareEnabled = !this.isScreenshareEnabled;
    },
    enumerateDevices() {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          this.videoDevices = devices.filter(
            (device) => device.kind === "videoinput"
          );
          this.audioInputDevices = devices.filter(
            (device) => device.kind === "audioinput"
          );
          this.audioOutputDevices = devices.filter(
            (device) => device.kind === "audiooutput"
          );

          // Get saved device IDs from localStorage
          const savedVideoDevice = localStorage.getItem("selectedVideoDevice");
          const savedAudioDevice = localStorage.getItem("selectedAudioDevice");
          const savedAudioOutputDevice = localStorage.getItem(
            "selectedAudioOutputDevice"
          );

          // Set selected devices based on saved values or first available
          this.selectedVideoDevice =
            savedVideoDevice && savedVideoDevice !== "none"
              ? savedVideoDevice
              : this.videoDevices.length
              ? this.videDevices[0].deviceId
              : "none";

          this.selectedAudioDevice =
            savedAudioDevice && savedAudioDevice !== "none"
              ? savedAudioDevice
              : this.audioInputDevices.length
              ? this.audioInputDevices[0].deviceId
              : "none";

          this.selectedAudioOutputDevices =
            savedAudioOutputDevice && savedAudioOutputDevice !== "none"
              ? savedAudioOutputDevice
              : this.audioOutputDevices.length
              ? this.audioOutputDevices[0].deviceId
              : "none";
        })
        .catch((error) => {
          console.error("Error enumerating devices:", error);
        });
    },
    openMicrophoneList() {
      this.microphoneList = true;
    },
    openAudioOutputList() {
      this.audioOutputList = true;
    },
    openVideoList() {
      this.videoList = true;
    },
    selectMicrophone(deviceId) {
      this.selectedAudioDevice = deviceId;
      window.emitter.emit("setMicrophone", deviceId);
      this.microphoneList = false;
    },
    selectVideo(deviceId) {
      this.selectedVideoDevice = deviceId;
      window.emitter.emit("setVideo", deviceId);
      this.videoList = false;
    },
    selectAudioOutput(deviceId) {
      this.selectedAudioOutputDevices = deviceId;
      window.emitter.emit("setSpeaker", deviceId);
      this.microphoneList = false;
    },
    onClickOutside(event) {
      this.videoList = false;
      this.microphoneList = false;
      this.audioOutputList = false;
    },
  },
};
</script>

<style scoped>
.controls {
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: #040e17;
  padding: 10px 0;
  z-index: 1000;
  overflow: visible;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.devices {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 0;
  justify-content: left;
  align-items: left;
  width: 100%;
  position: relative;
  padding-left: 5px;
  overflow: visible;
}

.device-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: visible; /* Allow dropdowns to extend outside */
}

/* Special styles for leave button */
.device-button.leave {
  margin-left: 5px;
  width: 52px;
  border-radius: 12px;
}

.device-button img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  background: #0a1624;
  border-radius: 50%;
  padding: 7px;
  transition: background-color 0.2s ease;
}

/* Special styles for leave button image */
.device-button.leave img {
  background: #e71115;
  border-radius: 8px;
  width: 56px;
  padding: 8px;
}

.device-button:hover img {
  background: #0f1f2f;
}

.device-button.leave:hover img {
  background: #ff1a1f;
}

/* Dropdown arrow styles */
.device-button .down-arrow-right {
  position: absolute;
  right: -12px;

  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

/* Dropdown menu styles */
.microphone-list,
.video-list {
  position: absolute;
  background: #273540;
  border-radius: 8px;
  padding: 16px;
  min-width: 250px;
  max-width: 300px;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  z-index: 100000;
  bottom: calc(100% + 8px);
  left: 0;
}

.device-section {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left;
}

.device-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.device-section-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  text-align: left;
}

.device-section-title img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background: none;
  padding: 0;
}

.form-check {
  margin: 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
}

.form-check-input {
  margin-right: 8px;
  cursor: pointer;
}

.form-check-label {
  font-size: 14px;
  opacity: 0.9;
  cursor: pointer;
  user-select: none;
  text-align: left;
  width: 100%;
}

/* Mobile styles */
@media screen and (max-width: 768px) and (orientation: portrait) {
  .controls {
    position: fixed;
    bottom: -20px;
    padding: 4px;
    margin-top: auto;
    overflow: visible;
    background: #040e17;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .devices {
    padding-left: 0;
    overflow: visible;
    justify-content: center; /* Center the controls on mobile */
  }

  .device-button {
    width: 32px;
    height: 32px;
    position: relative;
    overflow: visible;
  }

  .device-button img {
    width: 32px;
    height: 32px;
    padding: 6px;
  }

  .device-button.leave {
    width: 44px;
  }

  .device-button.leave img {
    width: 44px;
    padding: 6px;
  }

  /* Adjust dropdown positions for mobile */
  .microphone-list,
  .video-list {
    position: absolute;
    bottom: calc(100% + 8px);
    left: -50px;
    min-width: 200px;
    max-width: 280px;
    width: calc(100vw - 64px);
  }

  /* Position adjustments for first and last buttons */
  .device-button:first-child .microphone-list,
  .device-button:first-child .video-list {
    left: 24px;
  }

  .device-button:last-child .microphone-list,
  .device-button:last-child .video-list {
    left: auto;
    right: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .controls {
    bottom: 50px;
  }
}

/* iPad landscape specific styles */
@media only screen and (min-width: 768px) and (max-width: 1240px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .controls {
    bottom: 45px;
  }
}

/* iPhone specific styles */
@media only screen and (max-width: 500px) and (orientation: portrait) {
  @supports (-webkit-touch-callout: none) {
    .controls {
      bottom: -20px;
    }
  }
}

/* iPhone specific styles */
@media only screen and (max-width: 900px) and (orientation: landscape) {
  @supports (-webkit-touch-callout: none) {
    .controls {
      bottom: 55px;
    }
  }
}
</style>
