<template>
  <div class="tile" :class="{ 'active-speaker': isActiveSpeaker }">
    <audio autoplay playsInline ref="audioRef">
      <track kind="captions" />
    </audio>

    <div class="position-relative">
      <template v-if="participant.video">
        <div class="video-container">
          <video autoplay muted playsInline ref="videoRef"></video>
          <p class="participant-name">{{ username }}</p>
        </div>
      </template>

      <template v-else>
        <no-video-tile :username="username"></no-video-tile>
      </template>
    </div>
  </div>
</template>

<script>
import NoVideoTile from "./NoVideoTile.vue";
import { mapGetters } from "vuex";

export default {
  name: "VideoTile",
  components: {
    NoVideoTile,
  },
  props: [
    "participant",
    "handleVideoClick",
    "handleAudioClick",
    "handleScreenshareClick",
    "leaveCall",
    "disableScreenShare",
    "isActiveSpeaker",
  ],
  data() {
    return {
      username: "Guest",
    };
  },
  computed: {
    ...mapGetters(["videoChatAudioMuted"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.username = this.participant?.user_name;
      this.handleVideo(this.participant);
      this.handleAudio(this.participant);

      if (this.$refs.videoRef) {
        this.$refs.videoRef.addEventListener(
          "canplay",
          this.handleVideoCanPlay
        );
      }
      if (this.$refs.audioRef) {
        this.$refs.audioRef.addEventListener(
          "canplay",
          this.handleAudioCanPlay
        );
      }
      navigator.mediaDevices.addEventListener("devicechange", this.playTracks);
    });
  },
  watch: {
    videoChatAudioMuted() {
      if (this.videoChatAudioMuted) {
        this.$refs.audioRef.muted = true;
      } else {
        this.$refs.audioRef.muted = false;
      }
    },
  },
  unmounted() {
    if (this.$refs.videoRef && this.handleVideoCanPlay) {
      this.$refs.videoRef.removeEventListener(
        "canplay",
        this.handleVideoCanPlay
      );
    }
    if (this.$refs.audioRef && this.handleAudioCanPlay) {
      this.$refs.audioRef.removeEventListener(
        "canplay",
        this.handleAudioCanPlay
      );
    }
    if (this.playTracks) {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        this.playTracks
      );
    }
  },
  updated() {
    this.username = this.participant?.user_name;
    // For later optimization, this can be done more selectively
    // using "track-started" and "track-stopped" events.
    this.handleVideo(this.participant);
    this.handleAudio(this.participant);
  },
  methods: {
    handleVideoCanPlay() {
      if (!this.$refs.videoRef) return;
      if (!this.$refs.videoRef.paused) return;
      this.$refs.videoRef.play().catch((e) => {
        if (e instanceof DOMException && e.name === "NotAllowedError") {
          console.error("Autoplay failed", e);
        } else console.error("Error playing video", e);
      });
    },
    handleAudioCanPlay() {
      if (!this.$refs.audioRef) return;
      if (!this.$refs.audioRef.paused) return;
      this.$refs.audioRef.play().catch((e) => {
        if (e instanceof DOMException && e.name === "NotAllowedError") {
          console.error("Autoplay failed", e);
        } else console.error("Error playing audio: ", e);
      });
    },
    playTracks() {
      if (this.handleVideoCanPlay) {
        this.handleVideoCanPlay();
      } else {
        console.warn("handleVideoCanPlay is not defined");
      }
      if (this.handleAudioCanPlay) {
        this.handleAudioCanPlay();
      } else {
        console.warn("handleAudioCanPlay is not defined");
      }
    },
    // Add srcObject to video element
    handleVideo() {
      const p = this.participant;

      // If the participant has their video off,
      // early out.
      if (!p?.video) return;

      try {
        const track = p?.tracks?.video?.persistentTrack;
        this.$refs.videoRef.srcObject = new MediaStream([track]);
        this.$refs.videoRef.load();
      } catch (e) {
        console.error("show up a modal to get user gesture", e);
      }
    },

    // Add srcObject to audio element
    handleAudio() {
      const p = this.participant;
      // If the participant is local or has their audio off,
      // early out.
      if (!p || p.local || !p.audio) return;

      try {
        const track = p?.tracks?.audio?.persistentTrack;
        this.$refs.audioRef.srcObject = new MediaStream([track]);
      } catch (e) {
        console.error("show up a modal to get user gesture", e);
      }
    },
  },
};
</script>

<style scoped>
.tile {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  aspect-ratio: 16/9;
  margin: 0;
  padding: 0;
  flex: 1 0 auto;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.participant-name {
  position: absolute;
  color: #fff;
  bottom: 5px;
  left: 5px;
  margin: 0;
  padding: 2px 6px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.video-container {
  background-color: #040e17;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 575px) {
  .video-container {
    aspect-ratio: 16/9;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  video {
    width: 100%;
    height: 100%;
  }

  .tile {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.tile.active-speaker {
  border: 1.5px solid #2e58c7;
}

@media screen and (max-width: 575px) {
  .tile.active-speaker {
    border-width: 2px;
  }
}

/* iPad-specific styles */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tile {
    width: 100%;
    flex: 1 0 auto;
    transition: width 0.3s ease, height 0.3s ease;
    will-change: width, height;
  }

  .video-container {
    width: 100%;
    height: 100%;
  }
}
</style>
