<script>
import CustomTooltip from "../components/CustomTooltip.vue";
import WebRtcControls from "../components/WebRtcControls.vue";
import { isMobile } from "mobile-device-detect";
import { WebRTCPlayer } from "@eyevinn/webrtc-player";
export default {
  name: "WebRtcPlayer",
  props: {
    streamUrl: String,
  },
  components: { CustomTooltip, WebRtcControls },
  emits: ["handle-resize", "loading-video"],
  data() {
    return {
      player: null,
      loadRetryInterval: null,
      stillImage:
        "https://static.pixelview.io/default_thumbnails/empty-thumbnail.jpg",
      imageFeatchInterval: null,
      volume: 0.2,
      showStillImage: false,
      isMobile: isMobile,
      connected: false,
      initialLoad: true,
      aspectRatio: 16 / 9,
      isIOS:
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
        (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream),
    };
  },
  methods: {
    async fetchStillImage() {
      const urlParts = this.streamUrl.split("/");
      urlParts.pop();
      const removedWhepExtension = urlParts.join("/");
      const imageUrl =
        "https://" +
        removedWhepExtension.split("//")[1] +
        "/thumbnail.jpg" +
        "?" +
        new Date().getTime(); // To force refresh
      const response = await fetch(imageUrl);

      // If stream is offline we want to use latest cache
      // instead of loading bad one
      if (response.ok && this.connected) {
        this.stillImage = imageUrl;
      }
    },
    setVolume(volume) {
      console.log("Setting volume to", volume);
      if (this.$refs.webrtcPlayer) {
        this.$refs.webrtcPlayer.volume = volume;
      }
    },
    initPlayer() {
      const videoEl = document.querySelector(".web-rtc-player");
      const containerEl = this.$refs.videoContainer;

      this.player = new WebRTCPlayer({
        video: videoEl,
        type: "whep",
      });
      this.player.load(new URL(this.streamUrl));

      // Add resize observer to maintain aspect ratio
      const resizeObserver = new ResizeObserver(() => {
        // Just emit the resize event without dimensions
        this.$emit("handle-resize");
      });

      resizeObserver.observe(containerEl);

      // Connection restored
      this.player.on("peer-connection-connected", () => {
        console.log("peer-connection-connected");
        this.connected = true;
        this.showStillImage = false;
        if (this.loadRetryInterval) {
          clearInterval(this.loadRetryInterval);
          this.loadRetryInterval = null;
        }
      });

      this.player.on("initial-connection-failed", () => {
        console.log("initial-connection-failed");
        this.connected = false;
        this.connectionFailed();
      });
      this.player.on("peer-connection-failed", () => {
        console.log("peer-connection-failed");
        this.connected = false;
        this.connectionFailed();
      });

      setTimeout(() => {
        this.$emit("loading-video", false);
      }, 3000);
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 4000);
    },
    connectionFailed() {
      this.showStillImage = true;
      if (this.loadRetryInterval) {
        clearInterval(this.loadRetryInterval);
      }
      this.loadRetryInterval = setInterval(() => {
        console.log("Running init player again");
        this.initPlayer();
      }, 6000);
    },
  },
  mounted() {
    this.initPlayer();
    this.imageFeatchInterval = setInterval(() => {
      this.fetchStillImage();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.imageFeatchInterval);
    clearInterval(this.loadRetryInterval);
  },
};
</script>

<template>
  <div class="player-wrapper">
    <div class="video-container" ref="videoContainer">
      <div v-show="showStillImage" class="still-image-container">
        <img :src="stillImage" class="still-image" />
        <div class="waiting-message">
          <p class="text-white">
            Waiting for stream to resume <span class="dot">.</span
            ><span class="dot">.</span><span class="dot">.</span>
          </p>
        </div>
      </div>
      <video
        ref="webrtcPlayer"
        v-show="!showStillImage"
        class="web-rtc-player"
        autoplay
        muted
        playsinline
        controls
      />
    </div>
    <web-rtc-controls v-if="player && !isIOS" :player="player" />
    <div class="tooltip-wrapper" :class="isMobile ? 'mobile-position' : ''">
      <custom-tooltip></custom-tooltip>
    </div>
  </div>
</template>

<style scoped lang="scss">
.player-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.video-container {
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: black;
  overflow: hidden;
}

.still-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; /* Ensure it's above the player */
}

.still-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.waiting-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.web-rtc-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  background: black;
}

.tooltip-wrapper .tooltip {
  left: -15px;
  bottom: 50px;
}
.tooltip-wrapper {
  float: right !important;
}

.dot {
  animation: dot 1s infinite;
  animation-fill-mode: both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
