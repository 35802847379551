<template>
  <div :style="{ backgroundColor: backgroundColor }">
    <div class="container-fluid">
      <div style="text-align: center">
        <Loader v-if="loading" />
        <div v-else class="container">
          <div class="text-start px-2 header">
            <img
              :src="logoImageURL ? logoImageURL : pixelviewLogo"
              class="logo-image"
            />
            <p
              v-if="sessionDescription"
              class="reduced-opacity"
              :style="{
                color: backgroundColor === '#000000' ? 'white' : 'black',
              }"
            >
              {{ sessionDescription }}
            </p>
          </div>
          <div class="media-container row margin-top">
            <div
              id="video-player-section"
              class="position-relative padding-80 w-100 h-100"
            >
              <div v-if="paused">
                <div class="video-player" id="video-player">
                  <div class="row position-relative">
                    <span
                      class="pause-message-text"
                      :style="{
                        color:
                          backgroundColor === '#000000' ? 'white' : 'black',
                      }"
                    >
                      {{ pauseMessage }}
                    </span>
                    <pause-player
                      v-if="pauseVideoURL"
                      :pauseVideoURL="pauseVideoURL"
                    />
                  </div>
                </div>
              </div>

              <div v-else class="video-content">
                <div
                  class="video-chat"
                  :class="{
                    expanded: isVideoChatExpanded,
                    visible: showVideoChat && inVideoCall,
                  }"
                >
                  <div
                    class="resize-handle"
                    @mousedown="startVideoChatResize"
                    @touchstart="startVideoChatResize"
                  ></div>
                  <div class="video-chat-wrapper">
                    <div class="video-chat-content">
                      <VideoChat
                        v-if="showVideoChat && inVideoCall"
                        ref="videoChat"
                        @update:controls="updateCallControls"
                      />
                    </div>
                    <call-controls
                      v-if="showVideoChat && inVideoCall && callControlsProps"
                      v-bind="callControlsProps"
                      :participant="{
                        audio: {
                          track: {
                            enabled: callControlsProps.isAudioEnabled,
                          },
                        },
                        video: {
                          track: {
                            enabled: callControlsProps.isVideoEnabled,
                          },
                        },
                      }"
                      :isDarkBackground="true"
                      :videoChatAudioMuted="false"
                      :audioOutputDevices="[]"
                      :videoDevices="[]"
                      :audioInputDevices="[]"
                    />
                  </div>
                </div>
                <div
                  class="video-player"
                  id="video-player"
                  :class="{ 'video-chat-active': inVideoCall }"
                >
                  <div class="video-content">
                    <video-player-skeleton
                      v-if="loadingVideo"
                      :showTextChat="showTextChat"
                      :message="
                        paused ? formattedPauseMessage : loadingVideoMessage
                      "
                    />
                    <div
                      v-show="!loadingVideo"
                      v-if="screenShare === null"
                      class="player-container"
                      id="player-container"
                    >
                      <vue-drawing-canvas
                        v-if="draw"
                        ref="VueCanvasDrawing1"
                        :key="canvasKey"
                        v-model:image="image"
                        :width="playerWidth"
                        :height="playerHeight"
                        :stroke-type="strokeType"
                        :line-cap="lineCap"
                        :line-join="lineJoin"
                        :fill-shape="fillShape"
                        :eraser="eraser"
                        :lineWidth="line"
                        :color="color"
                        :background-color="'transparent'"
                        :background-image="backgroundImage"
                        :watermark="watermark"
                        :initial-image="strokes"
                        saveAs="png"
                        :styles="{
                          border: 'solid 0px #fff',
                        }"
                        :lock="disabled"
                        :additional-images="additionalImages"
                        @mouseup="sendStrokes"
                        @touchend="sendStrokes"
                      />

                      <template
                        v-if="player === 'WHEP'"
                        class="media-container__template"
                      >
                        <web-rtc-player
                          v-if="streamStatus == 'online'"
                          @handle-resize="handleResize"
                          @loading-video="setLoadingVideo"
                          :streamUrl="streamUrl"
                        />
                      </template>

                      <template v-else>
                        <main-player
                          v-if="streamStatus == 'online'"
                          @handle-resize="handleResize"
                          @loading-video="setLoadingVideo"
                          @error-message="setPlayerErrorMessage"
                          :streamUrl="streamUrl"
                        />
                      </template>
                    </div>
                    <div v-if="screenShare">
                      <div class="video-share-section"></div>
                      <div id="video-share">
                        <div>
                          <vue-drawing-canvas
                            v-if="draw"
                            ref="VueCanvasDrawing2"
                            :key="canvasKey"
                            v-model:image="image"
                            :width="playerWidth"
                            :height="playerHeight"
                            :stroke-type="strokeType"
                            :line-cap="lineCap"
                            :line-join="lineJoin"
                            :fill-shape="fillShape"
                            :eraser="eraser"
                            :lineWidth="line"
                            :color="color"
                            :background-color="'transparent'"
                            :background-image="backgroundImage"
                            :watermark="watermark"
                            :initial-image="strokes"
                            saveAs="png"
                            :styles="{
                              border: 'solid 0px #fff',
                            }"
                            :lock="disabled"
                            :additional-images="additionalImages"
                            @mouseup="sendStrokes"
                            @touchend="sendStrokes"
                          />
                          <screenshare-tile :participant="screenShare" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Chat
                  v-if="!textChatHiddenByAdmin"
                  class="chat-section"
                  :class="showTextChat ? '' : 'hide-chat-wrapper'"
                  :token="twilioToken"
                  :userID="twilioUserID"
                  @handle-resize="handleResize"
                />
              </div>
              <div>
                <toolbar
                  @clicked-undo-draw-stroke="undoDrawStroke"
                  @handle-resize="handleResize"
                ></toolbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import MainPlayer from "../components/MainPlayer.vue";
import Message from "../components/Message.vue";
import Loader from "../components/Loader.vue";
import StreamInfo from "../components/StreamInfo.vue";
import VideoChat from "../components/videochat/VideoChat.vue";
import VueDrawingCanvas from "vue-drawing-canvas";
import pixelviewLogo from "../assets/logo.png";
import ScreenshareTile from "../components/videochat/ScreenshareTile.vue";
import Toolbar from "../components/Toolbar";
import VideoPlayerSkeleton from "../components/VideoPlayerSkeleton";
import PausePlayer from "../components/PausePlayer.vue";
import Chat from "../components/textchat/Chat.vue";
import WebRtcPlayer from "../components/WebRtcPlayer.vue";
import CallControls from "../components/videochat/CallControls.vue";

export default {
  name: "Home",
  components: {
    WebRtcPlayer,
    VideoPlayerSkeleton,
    Toolbar,
    MainPlayer,
    Message,
    StreamInfo,
    Loader,
    VideoChat,
    VueDrawingCanvas,
    ScreenshareTile,
    PausePlayer,
    Chat,
    CallControls,
  },
  data() {
    return {
      pixelviewLogo,
      checkStreamStatus: null,
      getStreamStatusTries: 0,
      loading: true,
      convertedVideo: "",
      window: {
        width: 0,
      },
      image: "",
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 5,
      strokeType: "dash",
      lineCap: "square",
      lineJoin: "miter",
      backgroundImage: null,
      watermark: null,
      additionalImages: [],
      resizeTimeout: null,
      loadingVideoMessage: "Loading stream",
      isVideoChatExpanded: false,
      isResizing: false,
      startX: 0,
      startWidth: 0,
      callControlsProps: null,
    };
  },
  mounted() {
    this.checkStreamStatus = setInterval(() => {
      this.getStreamStatus();
    }, 1500);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.colorOptions = this.getDistinctColors();
    this.color = this.colorOptions[0];
    this.strokes = this.defaultStrokes;
    this.myStrokes = this.defaultStrokes;
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    formattedPauseMessage() {
      if (this.pauseMessage !== " ") {
        return this.pauseMessage;
      }
      return "Stream paused";
    },
    loadingVideo: {
      get() {
        return this.$store.state.loadingVideo;
      },
      set(value) {
        this.$store.commit("setLoadingVideo", value);
      },
    },
    defaultStrokes() {
      // Add some default strokes otherwise it crashes
      return Array.from({ length: this.colorOptions.length }, (_, i) => ({
        type: "dash",
        from: {
          x: 0,
          y: 0,
        },
        coordinates: [],
        color: this.colorOptions[i],
        width: 5,
        fill: false,
      }));
    },
    draw: {
      get() {
        return this.$store.state.draw;
      },
      set(value) {
        this.$store.commit("setDraw", value);
      },
    },
    myStrokes: {
      get() {
        return this.$store.state.myStrokes;
      },
      set(value) {
        this.$store.commit("setMyStrokes", value);
      },
    },
    playerWidth: {
      get() {
        return this.$store.state.playerWidth;
      },
      set(value) {
        this.$store.commit("setPlayerWidth", value);
      },
    },
    playerHeight: {
      get() {
        return this.$store.state.playerHeight;
      },
      set(value) {
        this.$store.commit("setPlayerHeight", value);
      },
    },
    canvasKey: {
      get() {
        return this.$store.state.canvasKey;
      },
      set(value) {
        this.$store.commit("setCanvasKey", value);
      },
    },
    colorOptions: {
      get() {
        return this.$store.state.colorOptions;
      },
      set(value) {
        this.$store.commit("setColorOptions", value);
      },
    },
    strokes: {
      get() {
        return this.$store.state.strokes;
      },
      set(value) {
        this.$store.commit("setStrokes", value);
      },
    },
    streamStatus: {
      get() {
        return this.$store.state.streamStatus;
      },
      set(value) {
        this.$store.commit("setStreamStatus", value);
      },
    },
    paused: {
      get() {
        return this.$store.state.paused;
      },
      set(value) {
        this.$store.commit("setPaused", value);
      },
    },
    pauseMessage: {
      get() {
        return this.$store.state.pauseMessage;
      },
      set(value) {
        this.$store.commit("setPauseMessage", value);
      },
    },
    color: {
      get() {
        return this.$store.state.color;
      },
      set(value) {
        this.$store.commit("setColor", value);
      },
    },
    backgroundColor: {
      get() {
        return this.$store.state.backgroundColor;
      },
      set(value) {
        this.$store.commit("setBackgroundColor", value);
        // Remove this line to prevent changing the body background color
        // document.body.style.backgroundColor = value;
      },
    },
    ...mapGetters([
      "sessionID",
      "showVideoChat",
      "showTextChat",
      "inVideoCall",
      "logoImageURL",
      "paused",
      "pauseMessage",
      "pauseVideoURL",
      "clientToken",
      "screenShare",
      "textChatHiddenByAdmin",
      "sessionDescription",
      "twilioToken",
      "twilioUserID",
      "player",
      "streamUrl",
      "localParticipant",
      "disableScreenShare",
      "leaveCall",
      "handleVideoClick",
      "handleAudioClick",
      "handleScreenshareClick",
    ]),
  },
  methods: {
    getAllStrokes() {
      let canvas1Strokes = [];
      let canvas2Strokes = [];

      if (this.$refs.VueCanvasDrawing1) {
        canvas1Strokes = this.$refs.VueCanvasDrawing1.getAllStrokes();
      }
      if (this.$refs.VueCanvasDrawing2) {
        canvas2Strokes = this.$refs.VueCanvasDrawing2.getAllStrokes();
      }
      return canvas1Strokes.concat(canvas2Strokes);
    },
    sendStrokes() {
      this.myStrokes = this.getAllStrokes().filter((stroke) =>
        this.colorOptions.includes(stroke.color)
      );
      this.$socket.sendObj({
        message: "NEW_BRUSH_STROKES",
        data: {
          strokes: {
            player_width: this.playerWidth,
            player_height: this.playerHeight,
            strokes: this.myStrokes,
          },
        },
      });
    },
    hexToRgb(hex) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return [r, g, b];
    },
    colorDistance(color1, color2) {
      const [r1, g1, b1] = this.hexToRgb(color1);
      const [r2, g2, b2] = this.hexToRgb(color2);
      return Math.sqrt((r1 - r2) ** 2 + (g1 - g2) ** 2 + (b1 - b2) ** 2);
    },
    isColorTooSimilar(newColor, colorArray) {
      const threshold = 100; // Adjust as needed
      return colorArray.some(
        (color) => this.colorDistance(newColor, color) < threshold
      );
    },
    getBrightness(hexColor) {
      const r = parseInt(hexColor.substr(1, 2), 16);
      const g = parseInt(hexColor.substr(3, 2), 16);
      const b = parseInt(hexColor.substr(5, 2), 16);
      return (r * 299 + g * 587 + b * 114) / 1000;
    },
    getDistinctColors() {
      let colors = [];
      while (colors.length < 3) {
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += Math.floor(Math.random() * 16).toString(16);
        }

        if (
          this.getBrightness(color) < 150 ||
          this.getBrightness(color) > 255 ||
          this.isColorTooSimilar(color, colors)
        ) {
          continue;
        }

        colors.push(color);
      }

      return colors;
    },
    handleResize() {
      // Prevent multiple rapid calls
      if (this.isResizing) {
        return;
      }

      console.log("handleResize called");
      clearTimeout(this.resizeTimeout);

      this.isResizing = true;
      this.resizeTimeout = setTimeout(() => {
        const oldWidth = this.playerWidth;
        const oldHeight = this.playerHeight;

        this.window.width = window.innerWidth;

        // Get the player container element
        const playerContainer = document.getElementById("player-container");
        if (!playerContainer) {
          console.warn("Player container not found");
          this.isResizing = false;
          return;
        }

        // Get dimensions from the active player or container
        let activePlayer = null;
        if (this.player === "WHEP") {
          activePlayer = playerContainer.querySelector(".web-rtc-player");
          console.log("Using WebRTC player dimensions");
        } else {
          activePlayer = playerContainer.querySelector("#sldp-player");
          console.log("Using Main player dimensions");
        }

        // Use player dimensions if available, otherwise fall back to container
        let newWidth, newHeight;
        if (
          activePlayer &&
          activePlayer.clientWidth > 0 &&
          activePlayer.clientHeight > 0
        ) {
          newWidth = Math.round(activePlayer.clientWidth);
          newHeight = Math.round(activePlayer.clientHeight);
          console.log("Player dimensions:", {
            width: newWidth,
            height: newHeight,
          });
        } else {
          newWidth = Math.round(playerContainer.clientWidth);
          newHeight = Math.round(playerContainer.clientHeight);
          console.log("Container dimensions (fallback):", {
            width: newWidth,
            height: newHeight,
          });
        }

        // Only update if dimensions have actually changed
        if (newWidth !== this.playerWidth || newHeight !== this.playerHeight) {
          this.playerWidth = newWidth;
          this.playerHeight = newHeight;

          // Scale strokes and redraw them on screen
          try {
            if (this.draw) {
              const reSizedStrokes = this.scaleStrokes(
                this.getAllStrokes(),
                oldWidth,
                oldHeight,
                this.playerWidth,
                this.playerHeight
              );
              this.strokes = reSizedStrokes;
              this.myStrokes = reSizedStrokes.filter((stroke) =>
                this.colorOptions.includes(stroke.color)
              );
              this.canvasKey++;
            }
          } catch (error) {
            console.log("Resize drawing error:", error);
          }
        } else {
          console.log("Dimensions unchanged, skipping update");
        }

        this.isResizing = false;
      }, 300);
    },
    setLoadingVideo(value) {
      this.loadingVideo = value;
      this.loading = false;
      console.log("loading video", value);
    },
    setPlayerErrorMessage(message) {
      this.loadingVideoMessage = message;
    },
    async getStreamStatus() {
      try {
        const res = await axios({
          url: process.env.VUE_APP_API + "/status/session/online",
          method: "get",
          params: { session_id: this.sessionID },
        });
        if (res.status === 200) {
          this.loading = false;
          this.streamStatus = res.data.status;
          if (this.streamStatus === "online") {
            clearInterval(this.checkStreamStatus);
          }
        }
      } catch (error) {
        console.log(error);
        // Ignore
      }
      this.getStreamStatusTries += 1;
      if (this.getStreamStatusTries > 16) {
        this.streamStatus = "online";
        clearInterval(this.checkStreamStatus);
      }
    },
    scaleStrokes(strokes, oldWidth, oldHeight, newWidth, newHeight) {
      let scaleX = newWidth / oldWidth;
      let scaleY = newHeight / oldHeight;

      strokes.forEach((stroke) => {
        stroke.from.x *= scaleX;
        stroke.from.y *= scaleY;
        stroke.coordinates.forEach((coord) => {
          coord.x *= scaleX;
          coord.y *= scaleY;
        });
      });

      return strokes;
    },
    undoDrawStroke() {
      if (this.$refs.VueCanvasDrawing1) this.$refs.VueCanvasDrawing1.undo();
      if (this.$refs.VueCanvasDrawing2) this.$refs.VueCanvasDrawing2.undo();
      this.sendStrokes();
    },
    startVideoChatResize(e) {
      this.isResizing = true;
      const clientX = e.type.includes("touch")
        ? e.touches[0].clientX
        : e.clientX;
      this.startX = clientX;
      this.startWidth = this.$el.querySelector(".video-chat").offsetWidth;
      document.body.classList.add("resizing");

      document.addEventListener("mousemove", this.handleVideoChatResize);
      document.addEventListener("touchmove", this.handleVideoChatResize);
      document.addEventListener("mouseup", this.stopVideoChatResize);
      document.addEventListener("touchend", this.stopVideoChatResize);
    },

    handleVideoChatResize(e) {
      if (!this.isResizing) return;

      requestAnimationFrame(() => {
        const clientX = e.type.includes("touch")
          ? e.touches[0].clientX
          : e.clientX;
        const width = Math.max(120, this.startWidth + (clientX - this.startX));
        const videoChat = this.$el.querySelector(".video-chat");
        if (videoChat) {
          videoChat.style.width = `${width}px`;
          this.isVideoChatExpanded = width > 120;
        }
      });
    },

    stopVideoChatResize() {
      this.isResizing = false;
      document.body.classList.remove("resizing");
      document.removeEventListener("mousemove", this.handleVideoChatResize);
      document.removeEventListener("touchmove", this.handleVideoChatResize);
      document.removeEventListener("mouseup", this.stopVideoChatResize);
      document.removeEventListener("touchend", this.stopVideoChatResize);
    },
    updateCallControls(props) {
      this.callControlsProps = props;
    },
  },
  beforeDestroy() {
    clearInterval(this.checkStreamStatus);
    document.removeEventListener("mousemove", this.handleVideoChatResize);
    document.removeEventListener("mouseup", this.stopVideoChatResize);
  },
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow: hidden; /* Disable scrolling */
}
.container-fluid {
  padding: 0px;
  margin: 0px;
}
.card {
  background-color: #091825;
  padding-left: 50px;
  padding-right: 50px;
  display: inline-block;
  text-align: center; /* To keep the text centered */
  border-radius: 20px;
  margin-top: 50px;
}

.card-title {
  font-family: "Noto Sans Mono", monospace;
  font-weight: 500;
  font-size: 2.2em; /* Make the text bigger */
}

.card-title-smaller {
  font-family: "Noto Sans Mono", monospace;
  font-weight: 300;
  font-size: 1.4em;
  margin-bottom: 0px;
}

.card-subtitle {
  color: #9ba2a9;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.card-text {
  font-family: "Noto Sans Mono", monospace;
  font-size: 1.2em; /* Make the text bigger */
}

.input-container,
.button-container {
  margin-bottom: 15px;
}

.form-control {
  width: 300px;
  height: 50px; /* Increase height */
  border: 1px solid grey;
  background-color: transparent;
  border-radius: 12px;
  color: white;
}

.form-control::placeholder {
  color: grey;
  opacity: 1;
  padding-left: 10px; /* Padding around the placeholder */
}

.form-control-password {
  /* ...existing styles... */
  color: white;
  padding-left: 10px; /* Padding around the placeholder */
  -webkit-text-security: disc;
}

input {
  padding-left: 10px; /* Padding around the placeholder */
}

.btn {
  width: 300px;
  height: 50px; /* Increase height */
  background-color: #2e58c7;
  border: none; /* Remove border */
  border-radius: 12px;
  color: white;
  font-family: "Mono Sans", monospace;
  font-weight: 600;
  font-size: 0.95em;
  letter-spacing: 1.5px;
}

.btn:disabled {
  background-color: #7b7b7b; /* Or any color you prefer */
  cursor: not-allowed;
}

.tooltip-text {
  display: none;
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.tooltip-text.active {
  display: block;
}

.video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.toolbar {
  background-color: black;
  z-index: 2000; /* Highest z-index for toolbar */
}

.video-chat img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.participant-name {
  /*right: none !important;*/
}

.call {
  width: 80px;
  margin-top: 20px;
  background-color: green;
  opacity: 0.85;
  padding: 14px 16px 15px;
  border-radius: 12px;
}

.margin-top {
  margin-bottom: 50px;
  margin-top: 0; /* Remove margin completely */
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.icon {
  height: 24px;
}

.section {
  padding-left: 45px;
}

.container {
  max-width: 100% !important;
  padding: 0px;
  margin: 0px;
}

.text-start.px-2 {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 0;
  padding-top: calc(env(safe-area-inset-top) + 8px); /* Add safe area inset */
  padding-bottom: 8px;
}

.logo-image {
  width: 200px;
  margin: 0;
  flex-shrink: 0; /* Prevent logo from shrinking */
}

.reduced-opacity {
  opacity: 0.7;
  margin: 0;
  font-size: 1.2em;
  flex-shrink: 1; /* Allow description to shrink if needed */
}

.pause-message-text {
  position: absolute;
  z-index: 2;
  top: 10%;
  font-size: 24px;
  right: 0;
  left: 0;
}

#VueDrawingCanvas {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
}

.padding-80 {
  padding-bottom: 75px;
  padding-top: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.hide-chat-wrapper {
  display: none;
}

@media (max-width: 991px) {
  .logo-image {
    width: 180px;
  }

  .hide-chat-wrapper {
    display: block;
    flex: 1;
    position: absolute !important;
    background: #040e17;
  }
}

@media (max-width: 800px) {
  .logo-image {
    width: 160px;
  }
}

@media (max-width: 600px) {
  .logo-image {
    width: 140px;
  }
}

@media (max-width: 575px) {
  .chat-section {
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .logo-image {
    width: 130px;
  }
}

@media (max-width: 420px) {
  .logo-image {
    width: 130px;
  }
}
.header {
  background-color: #040e17 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05); /* Added subtle border to match toolbar */
}

:root {
  --video-chat-height: 0px;
  --toolbar-height: 75px;
  --default-offset: 120px;
}

.video-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0;
  position: relative;
  overflow: hidden;
}

.video-chat {
  position: relative;
  width: 0;
  height: calc(100vh - 100px);
  background: #040e17;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.3s ease;
  z-index: 2;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: visible !important;
  min-width: 0;
}

.video-chat.visible {
  opacity: 1;
  transform: translateX(0);
  width: 160px;
  min-width: 160px;
}

.video-chat:not(.visible) {
  width: 0 !important;
  min-width: 0 !important;
}

.video-player {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  transition: margin-left 0.3s ease, width 0.3s ease;
  width: calc(100% - 160px);
}

.video-chat:not(.visible) + .video-player {
  width: 100% !important;
  margin-left: 0;
}

.video-chat-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.video-chat-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 8px;
  position: relative;
  z-index: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
}

/* Add iPad-specific styles for video chat */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .video-chat {
    transition: width 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
    will-change: width, opacity, transform;
    display: flex;
    flex-direction: column;
  }

  .video-chat-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    flex: 1 0 auto;
  }

  .video-chat.visible {
    min-width: 160px;
  }

  .video-chat.expanded {
    min-width: 240px;
  }

  /* Ensure video tiles resize properly when video chat is resized */
  .video-chat.visible .participants-container,
  .video-chat.expanded .participants-container {
    width: 100%;
    flex: 1 0 auto;
  }

  .video-chat.visible .participant-item,
  .video-chat.expanded .participant-item {
    width: 100%;
    flex: 1 0 auto;
  }
}

/* Mobile styles */
@media (max-width: 768px) and (orientation: portrait) {
  .video-chat {
    position: fixed;
    bottom: 81px;
    left: 0;
    right: 0;
    height: 120px;
    min-height: unset;
    max-height: 120px;
    transform: translateY(100%);
    width: 100% !important;
    z-index: 99;
    margin: 0;
    padding: 0;
    background: #040e17;
    overflow: visible !important;
  }

  .video-chat.visible {
    transform: translateY(0);
    height: 120px;
  }

  .video-chat-wrapper {
    height: 100%;
    overflow: visible !important;
  }

  .video-chat-content {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 2px;
    display: flex;
    flex-direction: row;
    margin: 0;
    flex: 1;
    min-height: 100px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-bottom: 60px;
  }

  .video-chat-content::-webkit-scrollbar {
    display: none;
  }

  /* Make video tiles more compact */
  .participants-container {
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    gap: 2px;
  }

  .participant-item {
    height: 100%;
    padding: 0;
    margin: 0;
    flex: 0 0 auto;
    width: 160px;
  }

  .tile {
    height: 100% !important;
    width: 100% !important;
  }

  .video-container {
    height: 100% !important;
  }

  .resize-handle {
    display: none;
  }

  /* Fix video player aspect ratio */
  .video-player {
    width: 100%;
    position: relative;
    aspect-ratio: 16/9;
    margin: 0 auto;
    padding: 0;
  }

  #video-player {
    width: 100%;
    position: relative;
    aspect-ratio: 16/9;
    margin: 0 auto;
    padding: 0;
  }

  .player-container {
    width: 100%;
    position: relative;
    aspect-ratio: 16/9;
    margin: 0 auto;
    padding: 0;
  }

  .video-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 98; /* Lower z-index */
    height: 75px;
  }

  /* Position call controls at the bottom */
  .call-controls {
    position: relative;
    margin-top: auto;
    background: #040e17;
    padding: 4px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 1000;
    height: auto;
    min-height: unset;
    flex-shrink: 0;
  }

  /* Remove any extra padding from container */
  .container-fluid,
  .container,
  .media-container {
    padding: 0;
    margin: 0;
  }

  /* Override any default padding */
  .padding-80 {
    padding: 0 !important;
  }
}

.controls {
  position: relative;
  background: #040e17;
  padding: 10px 0;
  z-index: 1000; /* Higher than video player but lower than toolbar */
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: rgba(46, 88, 199, 0.3);
  cursor: col-resize;
  transition: background 0.2s;
  z-index: 1000;
}

.resize-handle:hover,
.resize-handle:active {
  background: rgba(46, 88, 199, 0.8);
  width: 5px;
}

body.resizing {
  cursor: col-resize;
  user-select: none;
  -webkit-user-select: none;
}

body.resizing * {
  user-select: none !important;
  -webkit-user-select: none !important;
}

#video-player {
  position: relative;
  width: 100%;
  max-width: calc(
    (
        100vh - var(--video-chat-height) - var(--toolbar-height) -
          var(--default-offset)
      ) * 16 / 9
  );
  margin: 0 auto;
  overflow: hidden;
  aspect-ratio: 16/9;
  padding-left: 10px;
  padding-right: 10px;
  transition: max-width 0.3s ease;
}

.video-chat:not(.visible) + .video-player #video-player {
  max-width: calc(
    (100vh - var(--toolbar-height) - var(--default-offset)) * 16 / 9
  );
}

.player-container {
  flex: 1;
  position: relative;
  width: 100%;
  min-height: 0;
  overflow: hidden;
  aspect-ratio: 16/9;
  transition: width 0.3s ease;
}

/* Add styles for when video chat is not visible */
.video-content:not(:has(.video-chat.visible)) .player-container {
  justify-content: flex-start; /* Ensure left alignment */
  width: 100%;
}

.video-player-skeleton {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
}

#video-player-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.chat-section {
  flex-shrink: 0;
  background: #040e17;
  border-left: 1px solid #333;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.hide-chat-wrapper {
  display: none;
}

/* Ensure any dropdowns within video-chat are above everything except toolbar */
.video-chat .controls,
.video-chat .microphone-list,
.video-chat .video-list {
  z-index: 100000; /* Higher z-index to match CallControls.vue */
}

/* Add iPad-specific styles */
@supports (-webkit-touch-callout: none) {
  .text-start.px-2 {
    padding-top: calc(
      env(safe-area-inset-top) + 15px
    ); /* Extra padding for iPad */
  }
}
/* iPhone specific styles */
@media only screen and (max-width: 500px) {
  @supports (-webkit-touch-callout: none) {
    .text-start.px-2 {
      padding-top: max(env(safe-area-inset-top), 100px);
    }
  }
}
</style>
