import { createStore } from 'vuex';
import chat from '@/store/modules/chat';

const store = createStore({
  state: {
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
      instance: null,
    },
    sessionID: '',
    clientToken: '',
    userName: '',
    paused: false,
    pauseMessage: 'Pause message',
    pauseVideoURL: '',
    pauseTimeout: null,
    reconnects: 0,
    videoChatToken: '',
    unreadTextMessage: false,
    textChatHiddenByUser: false,
    textChatHiddenByAdmin: false,
    logoImageURL: '',
    sessionDescription: '',
    streamStatus: 'offline',
    showVideoChat: true,
    showTextChat: true,
    inVideoCall: false,
    name: '',
    canvasKey: 0, // For forcing a redraw of the canvas
    playerWidth: 0,
    playerHeight: 0,
    colorOptions: [],
    myStrokes: [],
    strokes: [],
    draw: false,
    userEnabledDraw: false,

    screenShare: null,
    color: '#000000',
    loadingVideo: true,
    iFrameFullscreen: false,
    videoChatAudioMuted: false,
    // New state for microphone mute state separate from speakers
    videoChatMicrophoneMuted: false,
    twilioToken: '',
    twilioUserID: '',
    drawerApp: false,
    player: null,
    streamUrl: null,
    backgroundColor: '#000000',
    hideBackgroundColorControls: false,
    initialLoad: true,
    autoJoinVideoCall: false,
    showDeviceModal: false,
  },
  mutations: {
    setAutoJoinVideoCall(state, payload) {
      state.autoJoinVideoCall = payload;
    },
    setHideBackgroundColorControls(state, payload) {
      state.hideBackgroundColorControls = payload;
    },
    setStreamUrl(state, payload) {
      state.streamUrl = payload;
    },
    setPlayer(state, payload) {
      state.player = payload;
    },
    setTwilioUserID(state, payload) {
      state.twilioUserID = payload;
    },
    setTwilioToken(state, payload) {
      state.twilioToken = payload;
    },
    setUserEnabledDraw(state, payload) {
      state.userEnabledDraw = payload;
    },
    setVideoChatAudioMuted(state, payload) {
      state.videoChatAudioMuted = payload;
    },
    setMicrophoneMuted(state, payload) {
      state.videoChatMicrophoneMuted = payload;
    },
    setIFrameFullscreen(state, payload) {
      state.iFrameFullscreen = payload;
    },
    setLoadingVideo(state, payload) {
      state.loadingVideo = payload;
    },
    setTextChatHiddenByAdmin(state, payload) {
      state.textChatHiddenByAdmin = payload;
    },
    setColor(state, payload) {
      state.color = payload;
    },
    setScreenShare(state, payload) {
      state.screenShare = payload;
    },
    setDraw(state, payload) {
      state.draw = payload;
    },
    setMyStrokes(state, payload) {
      state.myStrokes = payload;
    },
    setPlayerWidth(state, payload) {
      state.playerWidth = payload;
    },
    setPlayerHeight(state, payload) {
      state.playerHeight = payload;
    },
    setCanvasKey(state, payload) {
      state.canvasKey = payload;
    },
    setColorOptions(state, payload) {
      state.colorOptions = payload;
    },
    setStrokes(state, payload) {
      state.strokes = payload;
    },
    setName(state, payload) {
      state.name = payload;
    },
    setPauseVideoURL(state, payload) {
      state.pauseVideoURL = payload;
    },
    setTextChatHiddenByUser(state, payload) {
      state.textChatHiddenByUser = payload;
    },
    setInVideoCall(state, payload) {
      state.inVideoCall = payload;
    },
    setShowTextChat(state, payload) {
      state.showTextChat = payload;
    },
    setShowVideoChat(state, payload) {
      state.showVideoChat = payload;
    },
    setVideoChatToken(state, payload) {
      state.videoChatToken = payload;
    },
    setSessionDescription(state, payload) {
      state.sessionDescription = payload;
    },
    setLogoImageURL(state, payload) {
      state.logoImageURL = payload;
    },
    setUnreadTextMessage(state, payload) {
      state.unreadTextMessage = payload;
    },
    setPaused(state, payload) {
      state.paused = payload;
    },
    setPauseMessage(state, payload) {
      state.pauseMessage = payload;
    },
    setUserName(state, payload) {
      state.userName = payload;
    },
    setSessionID(state, payload) {
      state.sessionID = payload;
    },
    setClientToken(state, payload) {
      state.clientToken = payload;
    },
    setStreamStatus(state, payload) {
      state.streamStatus = payload;
    },
    SOCKET_USER_KICKED(state) {
      localStorage.setItem('kicked_from_session', state.sessionID);
      window.location.reload();
    },
    SOCKET_SESSION_DELETED(state, message) {
      console.log(JSON.stringify(message));

      if (message && message.data && message.data.TEXT_CHAT_RESET) {
        localStorage.removeItem('twilioUserID');
        console.log('twilio token removed');
      }
      window.location.reload();
    },
    SOCKET_PAUSE_STATUS_UPDATED(state, message) {
      state.pauseMessage = message.data.pause_message;
      state.paused = message.data.paused;
      if (!state.pauseVideoURL) {
        // Because of logic in Home. If we dont have a video we show skeleton instead.
        if (state.paused) {
          state.loadingVideo = true;
        } else {
          state.loadingVideo = false;
        }
      }
    },
    SOCKET_NEW_BRUSH_STROKES(state, message) {
      console.log('SOCKET_NEW_BRUSH_STROKES');
      let oldStrokes = state.strokes;
      let newStrokes = message.data.strokes.strokes;
      const remotePlayerWidth = Math.round(message.data.strokes.player_width);
      const remotePlayerHeight = Math.round(message.data.strokes.player_height);
      
      
      // Calculate scale factors using rounded values
      const scaleX = state.playerWidth / remotePlayerWidth;
      const scaleY = state.playerHeight / remotePlayerHeight;
      
      // Get the colors of new strokes = which users sent them
      const usersColors = [];
      newStrokes.forEach((stroke) => {
        const color = stroke.color;
        if (!usersColors.includes(color)) {
          usersColors.push(color);
        }
      });

      // Filter out empty strokes
      newStrokes = newStrokes.filter((stroke) => {
        return stroke.coordinates.length > 0;
      });

      // iOS sends array of empty coordinates so remove them
      //make coordinates [{"y": 0,"x": 0}] into []
      newStrokes = newStrokes.filter((stroke) => {
        if (stroke.coordinates.length == 1) {
          return stroke.coordinates[0].x !== 0 && stroke.coordinates[0].y !== 0;
        }
        return true;
      });

      // Remove the sending user's old strokes
      state.strokes = state.strokes.filter((stroke) => {
        return !usersColors.includes(stroke.color);
      });

      // Scale the strokes with precise calculations
      newStrokes.forEach((stroke) => {
        // Scale starting point
        stroke.from.x = Math.round(stroke.from.x * scaleX);
        stroke.from.y = Math.round(stroke.from.y * scaleY);
        
        // Scale each coordinate
        stroke.coordinates.forEach((coord) => {
          coord.x = Math.round(coord.x * scaleX);
          coord.y = Math.round(coord.y * scaleY);
        });
      });

      state.strokes = state.strokes.concat(newStrokes);
      state.strokes = state.strokes.concat(
        state.myStrokes.filter((stroke) => !state.strokes.includes(stroke))
      );

      // Toggle drawing only if it was started remotely
      if (state.strokes.length > 3) {
        state.draw = true;

        // If we were not drawing and another user triggered us
        // we need to request all strokes from all users to catch up
        if (oldStrokes.length == 3)
          state.socket.instance.sendObj({
            message: 'REQUEST_BRUSH_STROKES',
            data: {},
          });
      } else if (state.strokes.length == 3) {
        // Only default null strokes
        if (!state.userEnabledDraw) {
          state.draw = false;
        }
      }

      state.canvasKey++; // Force a redraw of the canvas
    },
    SOCKET_REQUEST_BRUSH_STROKES(state, message) {
      console.log('RECEIVED REQUEST_BRUSH_STROKES');
      state.socket.instance.sendObj({
        message: 'NEW_BRUSH_STROKES',
        data: {
          strokes: {
            player_width: state.playerWidth,
            player_height: state.playerHeight,
            strokes: state.myStrokes,
          },
        },
      });
    },
    SOCKET_SEND_PING(state) {
      state.socket.instance.sendObj({
        message: 'PONG_RESPONSE',
        data: {},
      });
    },
    SOCKET_TOKEN_EXPIRED() {
      // Used my new api4 to close 
      console.log('SOCKET_TOKEN_INVALID');
      window.location.reload();
    },
    SOCKET_ADD_VIEWER_WEB(_, message) {
      console.log('SOCKET_ADD_VIEWER');
    },
    UPDATE_VIDEOCHAT_SETTINGS(state, message) {
      if (message.data && message.data.hasOwnProperty('muted')) {
        // Update ONLY the mic mute state, not the speaker mute state
        state.videoChatMicrophoneMuted = message.data.muted;
      }
    },
    SOCKET_ONOPEN(state, event) {
      console.log('SOCKET_ONOPEN');
      state.socket.isConnected = true;
      state.socket.instance = event.currentTarget;

      // Initial ADD VIEWER is done in login
      // then here we just update the conID
      // Old viewer is deleted in DB on disconnect.
      // So we need to create a new viewer on reconnect.
      state.socket.instance.sendObj({
        message: 'ADD_VIEWER_WEB',
        data: {
          name: state.name,
        },
      });

      if (state.initialLoad) {
        setTimeout(() => {
          state.initialLoad = false;
          state.socket.instance.sendObj({
            message: 'REQUEST_BRUSH_STROKES',
            data: {},
          });
        }, 10000);
      }
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR() {
      console.log('SOCKET_ONERROR');
    },
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message;
    },
    SOCKET_RECONNECT() {
      console.log('SOCKET_RECONNECT');
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    setDrawerApp(state, payload) {
      state.drawerApp = payload;
    },
    setBackgroundColor(state, color) {
      state.backgroundColor = color;
    },
    setShowDeviceModal(state, value) {
      state.showDeviceModal = value;
    },
    sendVideoChatMessage(state, { message, data }) {
      data.session_id = state.sessionID;
      state.socket.instance.sendObj({
          message,
          data,
      });
    },
  },
  actions: {
    sendMessage(context, message) {
      // .....
      Vue.prototype.$socket.send(message);
      // .....
    },
    cancelDrawing({ commit }) {
      commit('setDraw', false);
      commit('setUserEnabledDraw', false);
      // Clear strokes if necessary
      commit('setStrokes', []);
      commit('setMyStrokes', []);
    },
    sendJoinedVideoChat({ commit }, { muted }) {
      commit('sendVideoChatMessage', {
        message: 'JOINED_VIDEOCHAT',
        data: { muted }
      });
    },
    sendLeftVideoChat({ commit }) {
      commit('sendVideoChatMessage', {
        message: 'LEFT_VIDEOCHAT',
        data: { }
      });
    },
    updateMicrophoneStatus({ commit }, { isMuted }) {
      commit('sendVideoChatMessage', {
        message: 'UPDATED_VIDEOCHAT_SETTINGS',
        data: { muted: isMuted }
      });
    },
  },
  getters: {
    sessionID: (state) => state.sessionID,
    paused: (state) => state.paused,
    pauseMessage: (state) => state.pauseMessage,
    clientToken: (state) => state.clientToken,
    userName: (state) => state.userName,
    socket: (state) => state.socket,
    videoChatToken: (state) => state.videoChatToken,
    unreadTextMessage: (state) => state.unreadTextMessage,
    logoImageURL: (state) => state.logoImageURL,
    sessionDescription: (state) => state.sessionDescription,
    streamStatus: (state) => state.streamStatus,
    showVideoChat: (state) => state.showVideoChat,
    showTextChat: (state) => state.showTextChat,
    inVideoCall: (state) => state.inVideoCall,
    announceNewUserInTextChat: (state) => state.announceNewUserInTextChat,
    textChatHiddenByUser: (state) => state.textChatHiddenByUser,
    pauseVideoURL: (state) => state.pauseVideoURL,
    name: (state) => state.name,
    strokes: (state) => state.strokes,
    colorOptions: (state) => state.colorOptions,
    canvasKey: (state) => state.canvasKey,
    playerWidth: (state) => state.playerWidth,
    playerHeight: (state) => state.playerHeight,
    myStrokes: (state) => state.myStrokes,
    draw: (state) => state.draw,
    screenShare: (state) => state.screenShare,
    color: (state) => state.color,
    textChatHiddenByAdmin: (state) => state.textChatHiddenByAdmin,
    loadingVideo: (state) => state.loadingVideo,
    iFrameFullscreen: (state) => state.iFrameFullscreen,
    videoChatAudioMuted: (state) => state.videoChatAudioMuted,
    // Add new getter for microphone state
    videoChatMicrophoneMuted: (state) => state.videoChatMicrophoneMuted,
    twilioToken: (state) => state.twilioToken,
    twilioUserID: (state) => state.twilioUserID,
    userEnabledDraw: (state) => state.userEnabledDraw,
    getDrawerApp: (state) => state.drawerApp,
    player: (state) => state.player,
    streamUrl: (state) => state.streamUrl,
    backgroundColor: (state) => state.backgroundColor,
    hideBackgroundColorControls: (state) => state.hideBackgroundColorControls,
    autoJoinVideoCall: (state) => state.autoJoinVideoCall,
    showDeviceModal: (state) => state.showDeviceModal,
  },
  modules: {
    chat,
  },
});

export default store;
